import React from 'react';
import { Helmet } from 'react-helmet';

const PortalLayout = ({ children }) => {
  return (
    <div className="bg-light">
      <Helmet title="Jobs" />

      {children}
    </div>
  );
};

export default PortalLayout;
