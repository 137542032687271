import { Auth, User } from 'apis/APISDK';

export const loadUserAccount = async () => User.loadUserAccount();

export const loginUser = async (email, password) =>
  Auth.login({ email, password });

export const signup = async (email) => Auth.signUp({ email });

export const signupCreate = async (name, tokenId) =>
  Auth.signUpCreate({ name, tokenId });

export const signupWithInvite = async (email, name, invite) =>
  Auth.signupByInvite({ email, name, invite });

export const verifyEmail = async (data) => Auth.verifyEmail(data);

export const logout = async (refreshToken) => Auth.logout({ refreshToken });

export const syncCart = async (cart) => User.addToCart({ cart });

export const forgotPassword = (data) => Auth.forgotPassword(data);

export const resetPassword = (data) => Auth.resetPassword(data);

export const resendVerifyEmail = (data) => Auth.resendVerificationEmail(data);

export const createOrganization = (data) => Auth.createOrganization(data);

export const getSignupDetails = async (tokenId) =>
  Auth.getSignupDetails(tokenId);
