import { Card, Typography } from 'antd';
import LocationInput from 'components/LocationInput';
import React from 'react';

const LocationFilter = ({
  onChange = () => {},
  title = 'Title here',
  value = null,
}) => (
  <Card className="border">
    <Typography.Title level={5}>{title}</Typography.Title>
    <LocationInput
      onChange={(e) => onChange(e)}
      value={value}
      placeholder={`Enter ${title}`}
    />
  </Card>
);

export default LocationFilter;
