import { Button, Form, Input, Modal } from 'antd';
import React from 'react';

const CreateJobPostModal = ({
  modalVisible = false,
  setModalVisible = () => {},
  onSubmit = () => [],
}) => {
  const handleSubmit = (values) => {
    const submissionValues = { ...values };
    onSubmit(submissionValues);
  };

  return (
    <Modal
      title="Create new job"
      centered
      open={modalVisible}
      onOk={() => setModalVisible(false)}
      onCancel={() => setModalVisible(false)}
      closable={false}
      bodyStyle={{ padding: '5px 24px 24px 24px' }}
      footer={null}
      getContainer={document.getElementById('root')}
    >
      <Form layout="vertical" onFinish={(values) => handleSubmit(values)}>
        <p className="mb-3">
          Enter the name of the job position you&apos;re hiring for. Once
          created, you&apos;ll be taken to its setup screen where you can
          configure it.
        </p>
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: 'Job title is required',
            },
          ]}
        >
          <Input autoFocus placeholder="Job title" />
        </Form.Item>
        <Button type="primary" className="me-3" htmlType="submit">
          Publish job
        </Button>
        <Button type="button" onClick={() => setModalVisible(false)}>
          Cancel
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateJobPostModal;
