import actions from './actions';

const initialState = {
  reviewList: [],
  loading: false,
  formLoading: false,
  formSubmitting: false,
  totalResults: 0,
  totalPages: 0,
  pageNo: 1,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    case actions.SET_LIST_REVIEW: {
      let reviewList = [];
      if (action.payload.pageNo <= 1)
        reviewList = [...action.payload.reviewList];
      else reviewList = [...state.reviewList, ...action.payload.reviewList];
      return { ...state, ...action.payload, reviewList };
    }

    case actions.SET_CREATE_REVIEW: {
      let newReview = action.payload;
      const currentUser = localStorage.getItem('user');
      if (currentUser) {
        const currentUserData = JSON.parse(currentUser);
        newReview = {
          ...newReview,
          createdBy: {
            id: newReview.createdBy,
            name: currentUserData?.name,
          },
        };
      }
      return { ...state, reviewList: [...state.reviewList, newReview] };
    }

    case actions.SET_UPDATE_REVIEW: {
      const updatedReview = action.payload;
      const updatedReviewList = state.reviewList.map((review) =>
        review.id === updatedReview.id
          ? { ...review, ...updatedReview }
          : review
      );
      return { ...state, reviewList: updatedReviewList };
    }

    case actions.SET_DELETE_REVIEW: {
      const reviewIdToDelete = action.payload;
      const updatedReviewList = state.reviewList.filter(
        (review) => review.id !== reviewIdToDelete
      );
      return { ...state, reviewList: updatedReviewList };
    }
    default:
      return state;
  }
}
