import { Teams } from 'apis/APISDK';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* LIST_TEAM_ADMINS({ payload }) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(Teams.listTeamByKey, {
      teamId: payload.teamId,
    });
    yield put({
      type: actions.SET_STATE,
      payload: { teamAdminList: data.results, loading: false },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* LIST_TEAM_MEMBERS({ payload }) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(Teams.listTeamByKey, {
      teamId: payload.teamId,
      key: 'users',
    });
    yield put({
      type: actions.SET_STATE,
      payload: { teamMemberList: data.results, loading: false },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export default function* contentsSaga() {
  yield all([takeEvery(actions.INVOKE_LIST_TEAM_ADMINS, LIST_TEAM_ADMINS)]);
  yield all([takeEvery(actions.INVOKE_LIST_TEAM_MEMBERS, LIST_TEAM_MEMBERS)]);
}
