import { message } from 'antd';
import { AI } from 'apis/APISDK';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { routes } from 'helpers/routes';
import { history } from 'helpers/history';
import actions from './actions';
import applicantActions from '../applicants/actions';

function* QUERY_AI(payloads) {
  const { payload } = payloads;
  yield put({
    type: applicantActions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  try {
    const { success, data = [] } = yield call(AI.filterByQuery, payload);

    if (success) {
      yield put({
        type: applicantActions.SET_STATE,
        payload: {
          currentStageApplicants: data,
          applicantsTotalPages: 1,
          applicantsTotalResults: data.length,
        },
      });
      if (data.length > 0) {
        history.push(
          routes.JOB_APPLICANT(payload.job, payload.stage, data[0].id)
        );
      }
    } else {
      message.error('Failed to query AI');
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: applicantActions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export default function* contentsSaga() {
  yield all([takeEvery(actions.INVOKE_QUERY_AI, QUERY_AI)]);
}
