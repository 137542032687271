import { Layout, Typography } from 'antd';
import React from 'react';
// import { routes } from 'helpers/routes';
// import { useHistory } from 'react-router-dom';

const { Header } = Layout;
const { Title } = Typography;

const OrganizationLayout = ({ children }) => {
  // const history = useHistory();

  return (
    <div className="vh-100 bg-light">
      <Header className="d-flex justify-content-center align-items-center bg-light px-3 border">
        <Title level={5} style={{ margin: '0' }}>
          joinus.team
        </Title>
      </Header>
      {children}
    </div>
  );
};

export default OrganizationLayout;
