import axios from 'axios';

export const get = (url, headers = {}) => {
  const result = new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.result);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
  return result;
};

export const getAwait = async (url, headers = {}) => {
  const response = await fetch(url, {
    method: 'GET',
    headers,
  });
  const res = await response.json();
  return res;
};

export const getFile = (url, headers = {}) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => res.blob())
      .then((blob) => {
        resolve(blob);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const post = (
  url,
  payload,
  headers = {
    'Content-Type': 'application/json',
  }
) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        payload,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result) {
          resolve(result.result);
        }
        if (result.error) {
          reject(result.error);
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const postAwait = async (
  url,
  payload,
  headers = {
    'Content-Type': 'application/json',
  }
) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        ...payload,
      }),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
};

export const put = (url, payload, headers = {}) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers,
      body: JSON.stringify({
        payload,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result) {
          resolve(result.result);
        }
        if (result.error) {
          reject(result.error);
        }
      })
      .catch(reject);
  });
};
export const Delete = (url, payload, headers = {}) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'DELETE',
      headers,
      body: JSON.stringify({
        payload,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result) {
          resolve(result.result);
        }
        if (result.error) {
          reject(result.error);
        }
      })
      .catch(reject);
  });
};

export const fileUpload = (
  url,
  filesArray,
  headers = {},
  fileType = 'image'
) => {
  const formData = new FormData();

  for (let i = 0; i < filesArray.length; i += 1) {
    formData.append(fileType, filesArray[i], filesArray[i].name);
  }
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      body: formData,
      headers,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result) {
          resolve(result.result);
        }
        if (result.error) {
          reject(result.error);
        }
      })
      .catch(reject);
  });
};
export const filesUploadPost = (
  url,
  filesArray,
  headers = {},
  fileType = 'image'
) => {
  const formData = new FormData();

  for (let i = 0; i < filesArray.length; i += 1) {
    formData.append(fileType, filesArray[i], filesArray[i].name);
  }
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: formData,
      headers,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result) {
          resolve(result.result);
        }
        if (result.error) {
          reject(result.error);
        }
      })
      .catch(reject);
  });
};

export const fileUploadPost = async (
  url,
  file,
  headers = {},
  onUploadProgress = null
) => {
  try {
    const config = {
      headers,
      onUploadProgress,
    };

    const response = await axios.post(url, file, config);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const fileUploadPatch = async (
  url,
  file,
  headers = {},
  onUploadProgress = null
) => {
  try {
    const config = {
      headers,
      onUploadProgress,
    };

    const response = await axios.patch(url, file, config);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
