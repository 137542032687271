import { message } from 'antd';
import { Screen } from 'apis/APISDK';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_SCREEN(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      Screen.createScreen,
      payload
    );

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      history.push(routes.SCREEN_LIST);
      yield put({ type: actions.INVOKE_LIST_SCREEN });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_SCREEN() {
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    // const { data } = yield call(Screen.listScreens);
    const { data } = yield call(Screen.listScreens);
    yield put({
      type: actions.SET_STATE,
      payload: { screenList: data.results, loading: false },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_SCREEN(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const { success, data } = yield call(Screen.getScreen, { id: payload.id });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false },
      });
      payload.next(data);
    } else {
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_SCREEN(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Screen.updateScreen, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      history.push(routes.SCREEN_LIST);
      yield put({ type: actions.INVOKE_LIST_SCREEN });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* DELETE_SCREEN(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(Screen.deleteScreen, payload);
    yield put({ type: actions.SET_STATE, payload: { screenList: [] } });
    yield put({ type: actions.INVOKE_LIST_SCREEN });
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_SCREEN,
      payload: { formType: payload.formType },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_SCREEN, CREATE_SCREEN),
    takeEvery(actions.INVOKE_LIST_SCREEN, LIST_SCREEN),
    takeEvery(actions.INVOKE_GET_SCREEN, GET_SCREEN),
    takeEvery(actions.INVOKE_UPDATE_SCREEN, UPDATE_SCREEN),
    takeEvery(actions.INVOKE_DELETE_SCREEN, DELETE_SCREEN),
  ]);
}
