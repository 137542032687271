import actions from './actions';

const initialState = {
  loading: false,
  formLoading: false,
  formSubmitting: false,
  messageList: null,
  messageLoading: false,
  messageSubmitting: false,
  lastCreatedMessage: null,

  messageTotalPages: 0,
  messageCurrentPage: 1,

  allMessageList: [],
  allMessageLoading: false,
  allMessageCurrentPage: 1,
  allMessageTotalPages: 0,
  allMessagesTotalResults: 0,
  allMessageLimit: 10,

  currentMessage: null,

  currentAllMessage: null,
  currentApplicantDetails: null,
  applicantLoading: null,

  applicantSearchList: [],
  applicantSearchListLoading: false,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    case actions.RESET_ALL_MESSAGES: {
      return {
        ...state,
        allMessageList: [],
        allMessageLoading: false,
        allMessageCurrentPage: 1,
        allMessageTotalPages: 0,
        allMessagesTotalResults: 0,
        allMessageLimit: 10,
      };
    }

    case actions.UPDATE_LAST_CREATED_MESSAGE: {
      const { data, params } = action.payload || {};
      const replyTo = params?.replyTo;

      if (!data) {
        return { ...state };
      }

      let newMessageList = Array.isArray(state.messageList)
        ? [...state.messageList]
        : [];

      // handles reply update
      if (
        replyTo &&
        state.currentMessage &&
        state.currentMessage.id === replyTo
      ) {
        // update message in message list with added reply id
        newMessageList = newMessageList.map((message) => {
          if (message.id === replyTo) {
            return {
              ...message,
              replies: [...(message.replies || []), data.id],
              createdAt: data?.createdAt,
            };
          }
          return message;
        });

        const updatedReplies = [...(state.currentMessage?.replies || []), data];
        return {
          ...state,
          messageList: newMessageList,
          currentMessage: {
            ...state.currentMessage,
            replies: updatedReplies,
          },
          lastCreatedMessage: data,
        };
      }
      newMessageList = [...newMessageList, data];

      return {
        ...state,
        messageList: newMessageList,
        lastCreatedMessage: data,
      };
    }

    case actions.UPDATE_LAST_CREATED_ALL_MESSAGE: {
      const { data, params } = action.payload || {};
      const replyTo = params?.replyTo;

      if (!data) {
        return { ...state };
      }

      let newAllMessageList = Array.isArray(state.allMessageList)
        ? [...state.allMessageList]
        : [];

      // incase its a reply, it will be updated in current message
      if (
        replyTo &&
        state.currentAllMessage &&
        state.currentAllMessage.id === replyTo
      ) {
        const updatedReplies = [
          ...(state.currentAllMessage?.replies || []),
          data,
        ];
        return {
          ...state,
          currentAllMessage: {
            ...state.currentAllMessage,
            replies: updatedReplies,
          },
          lastCreatedMessage: data,
        };
      }
      newAllMessageList = [...newAllMessageList, data];

      return {
        ...state,
        allMessageList: newAllMessageList,
        lastCreatedMessage: data,
      };
    }

    case actions.SET_ADD_ALL_MESSAGE_LIST: {
      if (state.allMessageCurrentPage === action.payload.allMessageCurrentPage)
        return { ...state };
      const { allMessageList } = state;
      allMessageList.push(...action.payload.allMessageList);

      return { ...state, ...action.payload, allMessageList };
    }

    default:
      return state;
  }
}
