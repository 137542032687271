import { message } from 'antd';
import { TalentCommunity } from 'apis/APISDK';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { handleApiError } from 'redux/error';
import actions from './actions';

function* CREATE_TALENT_APPLICANT(payloads) {
  const { payload, next } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      data,
      success,
      message: resMessage,
      status,
    } = yield call(TalentCommunity.createTalentApplicant, payload);
    if (success) {
      message.success(resMessage);
      if (next) next();
      yield put({
        type: actions.SET_ADD_TALENT_APPLICANT,
        payload: { data },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_TALENT_COMMUNITY({ payload }) {
  if (payload.page === 1) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
        talentCommunityList: [],
      },
    });
  }
  try {
    const {
      data,
      success,
      status,
      message: resMessage,
    } = yield call(TalentCommunity.listTalentCommunity, payload);
    if (success) {
      if (data.page > 1) {
        yield put({
          type: actions.SET_ADD_TALENT_APPLICANT_LIST,
          payload: {
            talentCommunityList: data.results,
            loading: false,
            applicantsCurrentPage: data.page,
          },
        });
      } else {
        yield put({
          type: actions.SET_STATE,
          payload: {
            talentCommunityList: data.results,
            applicantsTotalPages: data.totalPages,
            applicantsTotalResults: data.totalResults,
            applicantLimit: data.limit,
            currentStage: payload.currentStage,
            loading: false,
            applicantsCurrentPage: data.page,
          },
        });
      }
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* UPDATE_TALENT_APPLICANT(payloads) {
  const { payload, updateCurrent = true, next } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const { data, id } = payload;
    const { success, message: resMessage } = yield call(
      TalentCommunity.updateTalentApplicant,
      { data, id }
    );
    if (success) {
      const { talentCommunity } = yield select();
      if (updateCurrent) {
        if (talentCommunity?.currentTalentApplicant?.id) {
          yield put({
            type: actions.INVOKE_GET_TALENT_APPLICANT,
            payload: {
              id: talentCommunity.currentTalentApplicant.id,
            },
          });
        }
      }
      if (next) next();
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      message.success(`Applicant updated`);
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* GET_TALENT_APPLICANT({ payload, updateType }) {
  yield put({
    type: actions.SET_STATE,
    payload: { currentLoading: true },
  });
  try {
    const {
      data,
      success,
      status,
      message: resMessage,
    } = yield call(TalentCommunity.getTalentApplicant, payload);
    if (success) {
      if (updateType === 'updateList') {
        yield put({
          type: actions.SET_UPDATE_TALENT_APPLICANT,
          payload: {
            id: payload.id,
            data,
          },
        });
      }
      yield put({
        type: actions.SET_STATE,
        payload: { currentTalentApplicant: data, currentLoading: false },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { currentLoading: false } });
  }
}

function* DELETE_TALENT_APPLICANT(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { formSubmitting: true } });
  try {
    const { success } = yield call(
      TalentCommunity.deleteTalentApplicant,
      payload
    );
    if (success) {
      yield put({
        type: actions.SET_DELETE_TALENT_APPLICANT,
        payload: {
          id: payload.id,
        },
      });
      message.success('Candidate archived');
    } else {
      message.error('Error encountered while deleting candidate');
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { formSubmitting: false },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_TALENT_APPLICANT, CREATE_TALENT_APPLICANT),
    takeEvery(actions.INVOKE_LIST_TALENT_COMMUNITY, LIST_TALENT_COMMUNITY),
    takeEvery(actions.INVOKE_GET_TALENT_APPLICANT, GET_TALENT_APPLICANT),
    takeEvery(actions.INVOKE_UPDATE_TALENT_APPLICANT, UPDATE_TALENT_APPLICANT),
    takeEvery(actions.INVOKE_DELETE_TALENT_APPLICANT, DELETE_TALENT_APPLICANT),
  ]);
}
