import { message } from 'antd';
import { Applicant, FormEntry, Tag } from 'apis/APISDK';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { handleApiError } from 'redux/error';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { toTitleCase } from 'helpers/toTitleCase';
import { FILTER_OPERATIONS, FILTERS } from 'helpers/constants';
import actions from './actions';

function* CREATE_APPLICANT(payloads) {
  const { payload, cb = null } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      success,
      message: resMessage,
      status,
    } = yield call(Applicant.createApplicant, payload);
    if (success) {
      message.success(resMessage);

      yield put({
        type: actions.INVOKE_LIST_APPLICANTS_BY_STAGE_ID,
        payload: {
          jobId: payload.jobId,
          currentStage: payload.currentStage,
        },
      });
      if (cb) cb();
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_APPLICANTS({ payload }) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const {
      data,
      success,
      status,
      message: resMessage,
    } = yield call(Applicant.listApplicant, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          applicantList: data.results,
          loading: false,
        },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

const formatFilterValue = (operation, value) => {
  switch (operation) {
    case FILTER_OPERATIONS.GREATER_THAN:
      return `${FILTERS.GREATER_THAN}${value}`;
    case FILTER_OPERATIONS.LESS_THAN:
      return `${FILTERS.LESS_THAN}${value}`;
    case FILTER_OPERATIONS.EQUAL_TO:
      return `${FILTERS.EQUAL_TO}${value}`;
    case FILTER_OPERATIONS.RANGE:
      return `${FILTERS.RANGE}${value.join('-')}`;
    case FILTER_OPERATIONS.CONTAINS:
      return `${FILTERS.CONTAINS}${value}`;
    case FILTER_OPERATIONS.DOES_NOT_CONTAIN:
      return `${FILTERS.NOT_CONTAINS}${value}`;
    case FILTER_OPERATIONS.EXACT_MATCH:
      return value;
    case FILTER_OPERATIONS.MULTIPLE_VALUES:
      return `${FILTERS.IS_ONE_OF}${value}`;
    case FILTER_OPERATIONS.IS_ONE_OF:
      return `${FILTERS.IS_ONE_OF}${value.join(',')}`;
    case FILTER_OPERATIONS.IS_NOT_ONE_OF:
      return `${FILTERS.IS_NOT_ONE_OF}${value.join(',')}`;
    case FILTER_OPERATIONS.REGEX:
      return `${FILTERS.REGEX}${value}`;
    case FILTER_OPERATIONS.YES_NO:
      return value ? FILTERS.YES : FILTERS.NO;
    case FILTER_OPERATIONS.CONTAINS_ANY:
      return `${FILTERS.CONTAINS_ANY}${value}`;
    case FILTER_OPERATIONS.CONTAINS_ALL:
      return `${FILTERS.CONTAINS_ALL}${value}`;
    case FILTER_OPERATIONS.EXISTS:
      return FILTERS.EXISTS;
    case FILTER_OPERATIONS.NOT_EXISTS:
      return FILTERS.NOT_EXISTS;
    default:
      return value;
  }
};

function* LIST_APPLICANTS_BY_STAGE_ID({ payload }) {
  if (payload.page === 1)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
        currentStage: payload.currentStage,
        currentStageApplicants: [],
      },
    });
  else
    yield put({
      type: actions.SET_STATE,
      payload: { currentStage: payload.currentStage },
    });
  try {
    const { jobs } = yield select();
    const filter = Object.entries(jobs.filter || {}).reduce(
      (acc, [key, value]) => {
        if (value?.operation && value?.value) {
          if (typeof value.value === 'object' && !Array.isArray(value.value)) {
            acc[key] = {
              code: formatFilterValue(
                FILTER_OPERATIONS.EXACT_MATCH,
                value.value.code
              ),
              value: formatFilterValue(value.operation, value.value.value),
            };
          } else acc[key] = formatFilterValue(value.operation, value.value);
        }
        return acc;
      },
      {}
    );
    const {
      data,
      success,
      status,
      messaged: resMessage,
    } = yield call(Applicant.listApplicant, {
      ...payload,
      filter,
    });

    if (success) {
      if (data.page > 1) {
        yield put({
          type: actions.SET_ADD_TO_APPLICANT_LIST,
          payload: {
            currentStageApplicants: data.results,
            loading: false,
            applicantsCurrentPage: data.page,
          },
        });
      } else {
        yield put({
          type: actions.SET_STATE,
          payload: {
            currentStageApplicants: data.results,
            applicantsTotalPages: data.totalPages,
            applicantsTotalResults: data.totalResults,
            applicantLimit: data.limit,
            currentStage: payload.currentStage,
            loading: false,
            applicantsCurrentPage: data.page,
          },
        });
      }
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_APPLICANT({ payload, updateType }) {
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const {
      data,
      success,
      status,
      message: resMessage,
    } = yield call(Applicant.getApplicant, payload);
    if (success) {
      if (updateType === 'updateList') {
        yield put({
          type: actions.SET_UPDATE_APPLICANT,
          payload: {
            applicantId: payload.id,
            data,
          },
        });
      }
      yield put({
        type: actions.SET_STATE,
        payload: { currentApplicant: data, formLoading: false },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_APPLICANT(payloads) {
  const { payload, updateCurrent, options } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const { data, applicantId } = payload;
    const {
      success,
      message: resMessage,
      data: responseData,
    } = yield call(Applicant.updateApplicant, { data, applicantId });
    if (success) {
      if (updateCurrent && applicantId) {
        yield put({
          type: actions.INVOKE_GET_APPLICANT,
          payload: {
            id: applicantId,
          },
        });
      } else {
        const { applicants } = yield select();

        const updatedData = options.updateTags
          ? { tags: responseData?.tags || [] }
          : payload.data;

        yield put({
          type: actions.SET_UPDATE_APPLICANT_LIST,
          payload: {
            currentApplicant: {
              ...applicants.currentApplicant,
              ...updatedData,
            },
            applicantId,
          },
        });

        yield put({
          type: actions.SET_STATE,
          payload: {
            currentApplicant: {
              ...applicants.currentApplicant,
              ...updatedData,
            },
          },
        });
      }
      if (options.cb) options.cb();
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      message.success('Applicant updated');
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* MOVE_APPLICANT_BY_STAGE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const { jobId, stageId, data, applicantId, stageName } = payload;
    const { success, message: resMessage } = yield call(
      Applicant.updateApplicant,
      { data, applicantId }
    );
    if (success) {
      yield put({
        type: actions.SET_MOVE_APPLICANT,
        payload: { applicantId },
      });
      message.success(`Applicant moved to ${toTitleCase(stageName)}`);
      const { applicants } = yield select();
      if (applicants?.currentApplicant?.id) {
        history.push(
          routes.JOB_APPLICANT(jobId, stageId, applicants?.currentApplicant?.id)
        );
      } else history.push(routes.JOB_STAGE(jobId, stageId));
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* UPDATE_APPLICANT_FORM_ENTRY(payloads) {
  const { payload, filename } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formDataSubmitting: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      FormEntry.updateApplicantFormEntry,
      payload
    );
    if (success) {
      if (filename) {
        const { applicants } = yield select();
        if (applicants.currentApplicant.id) {
          yield put({
            type: actions.INVOKE_GET_APPLICANT,
            payload: {
              id: applicants.currentApplicant.id,
            },
          });
        }
      }
      yield put({
        type: actions.SET_STATE,
        payload: { formDataSubmitting: false },
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formDataSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.SET_STATE,
      payload: { formDataSubmitting: false },
    });
  }
}

function* DELETE_APPLICANT(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { formSubmitting: true } });
  try {
    const { success } = yield call(Applicant.deleteApplicant, payload);
    if (success) {
      message.success('Candidate archived');
    } else {
      message.error('Error encountered while deleting candidate');
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { formSubmitting: false },
    });
  }
}

function* GET_APPLICANT_ACTIVITY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: { activityLoading: true },
  });
  try {
    const { page = 1 } = payload;
    const { data } = yield call(Applicant.getApplicantActivity, payload);
    if (page > 1) {
      yield put({
        type: actions.INVOKE_PUSH_PAGE_ACTIVITY,
        payload: {
          data,
          activityLoading: false,
        },
      });
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          currentApplicantActivity: data,
          activityLoading: false,
        },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { activityLoading: false } });
  }
}

function* MOVE_BULK_APPLICANT_BY_STAGE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const { jobId, stageId, data, stageName } = payload;
    const { success, message: resMessage } = yield call(
      Applicant.updateBulkApplicant,
      { data: { ...data } }
    );
    if (success) {
      yield put({
        type: actions.SET_MOVE_BULK_APPLICANT,
        payload: { candidates: data.candidates },
      });
      message.success(`Applicants moved to ${toTitleCase(stageName)}`);
      const { applicants } = yield select();
      if (applicants?.currentApplicant?.id) {
        history.push(
          routes.JOB_APPLICANT(jobId, stageId, applicants?.currentApplicant?.id)
        );
      } else history.push(routes.JOB_STAGE(jobId, stageId));
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_ALL_APPLICANTS({ payload }) {
  if (payload.page === 1)
    yield put({
      type: actions.SET_STATE,
      payload: {
        allApplicantsLoading: true,
        allApplicantsList: [],
      },
    });
  try {
    const {
      data,
      success,
      status,
      messaged: resMessage,
    } = yield call(Applicant.listAllApplicant, payload);

    if (success) {
      if (data.page > 1) {
        yield put({
          type: actions.SET_ADD_TO_ALL_APPLICANT_LIST,
          payload: {
            allApplicantsList: data.results,
            allApplicantsCurrentPage: data.page,
            allApplicantsTotalPages: data.totalPages,
            allApplicantsTotalResults: data.totalResults,
            allApplicantsLoading: false,
          },
        });
      } else {
        yield put({
          type: actions.SET_STATE,
          payload: {
            allApplicantsList: data.results,
            allApplicantsTotalPages: data.totalPages,
            allApplicantsTotalResults: data.totalResults,
            allApplicantsLimit: data.limit,
            allApplicantsCurrentPage: data.page,
            allApplicantsLoading: false,
          },
        });
      }
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { allApplicantsLoading: false },
    });
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { allApplicantsLoading: false },
    });
  }
}

function* GET_APPLICANT_ITEM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: { applicantItemLoading: true },
  });
  try {
    const {
      data,
      success,
      status,
      message: resMessage,
    } = yield call(Applicant.getApplicant, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { currentApplicantItem: data, applicantItemLoading: false },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.SET_STATE,
      payload: { applicantItemLoading: false },
    });
  }
}

function* SEARCH_APPLICANTS({ payload }) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { searchListLoading: true },
    });
    const {
      data,
      success,
      status,
      messaged: resMessage,
    } = yield call(Applicant.listApplicant, payload);

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          searchList: data.results,
          searchListTotalPages: data.totalPages,
          searchListTotalResults: data.totalResults,
          searchListCurrentPage: data.page,
        },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { searchListLoading: false },
    });
  }
}

function* CREATE_TAG(payloads) {
  const { payload, options } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { creatingTagLoading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
      status,
    } = yield call(Tag.createTag, payload);
    if (success) {
      const { currentApplicant } = yield select((state) => state.applicants);

      if (options && options.applicantId === currentApplicant.id) {
        const tagIds = currentApplicant?.tags.map((tag) => tag.id);
        yield put({
          type: actions.INVOKE_UPDATE_APPLICANT,
          payload: {
            data: { tags: [...tagIds, data.id] },
            applicantId: options.applicantId,
          },
          options: {
            updateTags: true,
          },
        });

        if (options.next) options.next();
      }
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.SET_STATE,
      payload: { creatingTagLoading: false },
    });
  }
}

function* LIST_TAGS({ payload }) {
  yield put({ type: actions.SET_STATE, payload: { tagListLoading: true } });
  try {
    const {
      data,
      success,
      status,
      message: resMessage,
    } = yield call(Tag.listTags, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          tagList: data.results,
        },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { tagListLoading: false } });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_APPLICANT, CREATE_APPLICANT),
    takeEvery(actions.INVOKE_LIST_APPLICANTS, LIST_APPLICANTS),
    takeEvery(
      actions.INVOKE_LIST_APPLICANTS_BY_STAGE_ID,
      LIST_APPLICANTS_BY_STAGE_ID
    ),
    takeEvery(actions.INVOKE_GET_APPLICANT, GET_APPLICANT),
    takeEvery(actions.INVOKE_GET_APPLICANT_ACTIVITY, GET_APPLICANT_ACTIVITY),
    takeEvery(actions.INVOKE_UPDATE_APPLICANT, UPDATE_APPLICANT),
    takeEvery(actions.INVOKE_MOVE_APPLICANT_BY_STAGE, MOVE_APPLICANT_BY_STAGE),
    takeEvery(
      actions.INVOKE_UPDATE_APPLICANT_FORM_ENTRY,
      UPDATE_APPLICANT_FORM_ENTRY
    ),
    takeEvery(actions.INVOKE_DELETE_APPLICANT, DELETE_APPLICANT),
    takeEvery(
      actions.INVOKE_MOVE_BULK_APPLICANT_BY_STAGE,
      MOVE_BULK_APPLICANT_BY_STAGE
    ),
    takeEvery(actions.INVOKE_LIST_ALL_APPLICANTS, LIST_ALL_APPLICANTS),
    takeEvery(actions.INVOKE_GET_APPLICANT_ITEM, GET_APPLICANT_ITEM),
    takeEvery(actions.INVOKE_SEARCH_CANDIDATES, SEARCH_APPLICANTS),
    takeEvery(actions.INVOKE_CREATE_TAG, CREATE_TAG),
    takeEvery(actions.INVOKE_LIST_TAGS, LIST_TAGS),
  ]);
}
