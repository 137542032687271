import actions from './actions';

const initialState = {
  stageList: [],
  currentStage: [],
  loading: false,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
