import { message } from 'antd';
import { Form } from 'apis/APISDK';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* LIST_FORM(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Form.listForm, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          forms: data?.results,
          formsTotalResults: data?.totalResults,
          formsTotalPages: data?.totalPages,
          loading: false,
        },
      });
    } else {
      message.error(resMessage);
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_FORM(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data } = yield call(Form.getForm, payload);
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { currentForm: data, loading: false },
      });
      if (payload.next) payload.next(data);
    } else {
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* UPDATE_FORM(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data } = yield call(Form.updateForm, payload);
    if (success && data) {
      yield put({
        type: actions.INVOKE_LIST_FORM,
        payload: { type: payload.type },
      });
      payload.next(data);
    }
  } catch (error) {
    payload.next();
    console.error(error);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* DELETE_FORM(payloads) {
  const { payload } = payloads;

  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(Form.deleteForm, payload);
    yield put({
      type: actions.INVOKE_LIST_FORM,
      payload: { type: payload.type },
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_FORM,
      payload: { type: payload.type },
    });
  }
}

function* CREATE_FORM(payloads) {
  const { payload } = payloads;

  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data } = yield call(Form.createForm, payload);
    if (success) {
      message.success('Form created');
      yield put({
        type: actions.INVOKE_LIST_FORM,
        payload: { type: payload.type },
      });
      if (payload.next) payload.next(data);
    } else message.error('Page creation failed');
  } catch (error) {
    payload.next();
    console.error(error);
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_LIST_FORM, LIST_FORM),
    takeEvery(actions.INVOKE_GET_FORM, GET_FORM),
    takeEvery(actions.INVOKE_UPDATE_FORM, UPDATE_FORM),
    takeEvery(actions.INVOKE_DELETE_FORM, DELETE_FORM),
    takeEvery(actions.INVOKE_CREATE_FORM, CREATE_FORM),
  ]);
}
