export const getTotalApplicationCount = (applicantCountData, jobId) => {
  return applicantCountData[jobId]?.totalCount || 0;
};

export const getStageApplicantCount = (applicantCountData, jobId, stage) => {
  return applicantCountData?.[jobId]?.stageCounts?.[stage] || 0;
};

export const addQueryParamToUrl = (url, paramName, paramValue) => {
  return url.includes('?')
    ? `${url}&${paramName}=${paramValue}`
    : `${url}?${paramName}=${paramValue}`;
};
