export default {
  SET_STATE: 'workflows/SET_STATE',
  SET_ADD_NEW_WORKFLOW: 'workflows/SET_ADD_NEW_WORKFLOW',

  INVOKE_LIST_WORKFLOWS: 'workflows/INVOKE_LIST_WORKFLOWS',
  INVOKE_GET_WORKFLOW: 'workflows/INVOKE_GET_WORKFLOW',
  INVOKE_CREATE_WORKFLOW: 'workflows/INVOKE_CREATE_WORKFLOW',
  INVOKE_UPDATE_WORKFLOW: 'workflows/INVOKE_UPDATE_WORKFLOW',
  INVOKE_UPDATE_WORKFLOW_STAGES: 'workflows/INVOKE_UPDATE_WORKFLOW_STAGES',

  INVOKE_DELETE_WORKFLOW: 'workflows/INVOKE_DELETE_WORKFLOW',

  INVOKE_CREATE_WORKFLOW_TEMPLATE_RULE:
    'workflows/INVOKE_CREATE_WORKFLOW_TEMPLATE_RULE',
  INVOKE_UPDATE_WORKFLOW_TEMPLATE_RULE:
    'workflows/INVOKE_UPDATE_WORKFLOW_TEMPLATE_RULE',
  INVOKE_DELETE_WORKFLOW_TEMPLATE_RULE:
    'workflows/INVOKE_DELETE_WORKFLOW_TEMPLATE_RULE',
  INVOKE_LIST_WORKFLOW_TEMPLATE_RULE:
    'workflows/INVOKE_LIST_WORKFLOW_TEMPLATE_RULE',

  INVOKE_UPDATE_WORKFLOW_RULES: 'workflows/INVOKE_UPDATE_WORKFLOW_RULES',
};
