import actions from './actions';

const initialState = {
  aiAgentsList: [],
  currentAIAgent: {},
  loading: false,
  aiAgentTotalResults: 0,
  aiAgentTotalPages: 0,
  aiAgentCurrentPage: 1,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
