import { APPLICANT_DETAIL_MENUS } from 'helpers/constants';
import actions from './actions';

const initialState = {
  applicantList: [],
  applicantsTotalPages: 0,
  applicantsTotalResults: 0,
  currentStageApplicants: [],
  selectedApplicantMenu: APPLICANT_DETAIL_MENUS.overview,
  currentApplicant: null,
  currentApplicantActivity: {},
  currentStage: null,
  loading: false,
  formLoading: false,
  formSubmitting: false,
  formDataSubmitting: false,

  jobId: null,
  applicantLimit: 10,
  applicantsCurrentPage: 1,

  allApplicantsList: [],
  allApplicantsCurrentPage: 1,
  allApplicantsTotalPages: 0,
  allApplicantsTotalResults: 0,
  allApplicantLimit: 10,

  allApplicantsLoading: false,

  currentApplicantItem: null,
  applicantItemLoading: false,

  searchList: [],
  searchListLoading: false,
  searchListTotalPages: 0,
  searchListTotalResults: 0,
  searchListCurrentPage: 1,

  tagList: [],
  tagListLoading: [],
  creatingTagLoading: false,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.RESET_STATE:
      return { ...state, ...initialState };

    case actions.SET_CURRENT_STAGE_APPLICANTS: {
      const currentStageApplicants = state.applicantList.filter((applicant) => {
        return applicant.stage === Number(action.payload.stageId);
      });
      delete action.payload.stageId;
      return { ...state, currentStageApplicants, ...action.payload };
    }

    case actions.SET_CURRENT_APPLICANT: {
      let currentApplicant = null;
      const hasApplicant = state.currentStageApplicants.filter((applicant) => {
        return applicant.id === Number(action.payload.applicantId);
      });
      // eslint-disable-next-line prefer-destructuring
      if (hasApplicant.length > 0) currentApplicant = hasApplicant[0];
      delete action.payload.applicantId;
      return { ...state, currentApplicant, ...action.payload };
    }
    case actions.SET_CREATE_APPLICANT: {
      const applicantList = [...state.applicantList, action.payload.data];
      return { ...state, applicantList };
    }
    case actions.SET_DELETE_APPLICANT: {
      const idToRemove = action.payload.applicantId;
      const applicantList = state.applicantList.filter(
        (item) => item.id !== idToRemove
      );
      return { ...state, applicantList };
    }
    case actions.SET_UPDATE_APPLICANT: {
      const idToUpdate = action.payload.applicantId;
      const applicantList = state.applicantList.map((obj) =>
        obj.id === idToUpdate ? { ...obj, ...action.payload.data } : obj
      );

      let { currentApplicant } = state;
      currentApplicant = { ...currentApplicant, ...action.payload.data };

      return { ...state, applicantList, currentApplicant };
    }

    case actions.SET_MOVE_APPLICANT: {
      const {
        currentStageApplicants,
        applicantsTotalResults,
        applicantLimit,
        applicantsTotalPages,
      } = state;

      const applicantIndex = currentStageApplicants.findIndex(
        (applicant) => applicant.id === action.payload.applicantId
      );

      const updatedApplicants =
        applicantIndex !== -1
          ? [
              ...currentStageApplicants.slice(0, applicantIndex),
              ...currentStageApplicants.slice(applicantIndex + 1),
            ]
          : currentStageApplicants;

      const newTotalResults = Math.max(0, applicantsTotalResults - 1);
      const newTotalPages = Math.ceil(newTotalResults / applicantLimit);
      const newCurrentPage =
        applicantsTotalPages === newTotalPages &&
        state.applicantsCurrentPage === applicantsTotalPages
          ? state.applicantsCurrentPage
          : Math.max(0, state.applicantsCurrentPage - 1);

      let currentApplicant = null;
      if (applicantIndex !== -1 && updatedApplicants.length > 0) {
        currentApplicant = updatedApplicants[applicantIndex];
        if (applicantIndex === updatedApplicants.length)
          currentApplicant = updatedApplicants[applicantIndex - 1];
      }

      return {
        ...state,
        currentApplicant,
        currentStageApplicants: updatedApplicants,
        applicantsTotalResults: newTotalResults,
        applicantsTotalPages: newTotalPages,
        applicantsCurrentPage: newCurrentPage,
      };
    }

    case actions.SET_MOVE_BULK_APPLICANT: {
      const { currentStageApplicants, applicantLimit, applicantsTotalPages } =
        state;
      const filteredObjects = currentStageApplicants.filter(
        (object) => !action.payload.candidates.includes(object.id)
      );

      const newTotalResults = Math.max(0, filteredObjects.length);
      const newTotalPages = Math.ceil(newTotalResults / applicantLimit);

      const newCurrentPage =
        applicantsTotalPages === newTotalPages &&
        state.applicantsCurrentPage === applicantsTotalPages
          ? state.applicantsCurrentPage
          : Math.max(0, state.applicantsCurrentPage - filteredObjects.length);

      let currentApplicant = null;
      // eslint-disable-next-line prefer-destructuring
      if (filteredObjects.length > 0) currentApplicant = filteredObjects[0];

      return {
        ...state,
        currentApplicant,
        currentStageApplicants: filteredObjects,
        applicantsTotalResults: newTotalResults,
        applicantsTotalPages: newTotalPages,
        applicantsCurrentPage: newCurrentPage,
      };
    }

    case actions.SET_ADD_TO_APPLICANT_LIST: {
      if (state.applicantsCurrentPage === action.payload.applicantsCurrentPage)
        return { ...state };
      const { currentStageApplicants } = state;
      currentStageApplicants.push(...action.payload.currentStageApplicants);

      return { ...state, ...action.payload, currentStageApplicants };
    }
    case actions.SET_ADD_TO_ALL_APPLICANT_LIST: {
      if (
        state.allApplicantsCurrentPage ===
        action.payload.allApplicantsCurrentPage
      )
        return { ...state };
      const { allApplicantsList } = state;
      allApplicantsList.push(...action.payload.allApplicantsList);

      return { ...state, ...action.payload, allApplicantsList };
    }
    case actions.INVOKE_PUSH_PAGE_ACTIVITY: {
      return {
        ...state,
        currentApplicantActivity: {
          ...state.currentApplicantActivity,
          results: [
            ...state.currentApplicantActivity.results,
            ...action.payload.data.results,
          ],
          totalPages: action.payload.data.totalPages,
          page: action.payload.data.page,
        },
      };
    }
    case actions.SET_UPDATE_APPLICANT_LIST: {
      const { currentStageApplicants } = state;

      const newApplicantList = currentStageApplicants.map((applicant) => {
        if (applicant.id === action.payload.applicantId) {
          return { ...applicant, ...action.payload.currentApplicant };
        }
        return applicant;
      });

      return { ...state, currentStageApplicants: newApplicantList };
    }
    default:
      return state;
  }
}
