/* eslint-disable no-unused-vars */
import { Alert, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import './styles.css';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';

const UpgradePlanPopup = () => {
  const [modal1Open, setModal1Open] = useState(false);

  useEffect(() => {
    const lastShown = localStorage.getItem('upgradePopupLastShown');
    const now = new Date();

    if (lastShown) {
      localStorage.setItem('isUpgradePopupShown', true);
    }

    if (!lastShown || now - new Date(lastShown) > 12 * 60 * 60 * 1000) {
      setModal1Open(true);
      localStorage.setItem('upgradePopupLastShown', now.toISOString());
    }
  }, []);

  if (!modal1Open) return null;

  return (
    <div className="position-absolute bottom-0 left-0 right-0 vw-100  bg-black">
      <Alert
        className="text-white bg-black"
        description={
          <div className="d-flex justify-content-between align-items-center py-4 px-3">
            <div>
              <h4 className="bg-black text-white">Upgrade to prime test</h4>
              <h6 className="text-secondary fw-medium">
                Empower your team with these additional features
              </h6>
            </div>

            <Button
              className="upgrade-to-prime-btn"
              onClick={() => history.push(routes.ORGANIZATION_PLAN_BILLING)}
            >
              Upgrade
            </Button>
          </div>
        }
        closable
        closeIcon={<CloseOutlined className="text-white" />}
        afterClose={() => {
          localStorage.setItem('isUpgradePopupShown', true);
        }}
      />
    </div>
  );
};

export default UpgradePlanPopup;
