import { message } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CallRecording } from 'apis/APISDK';
import { handleApiError } from 'redux/error';
import actions from './actions';

function* LIST_RECORDINGS(payloads) {
  const { payload } = payloads;
  const isCandidateList = !!payload?.candidate;

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      ...(isCandidateList ? {} : { allRecordingLoading: true }),
    },
  });

  try {
    const { data, success, status } = yield call(
      CallRecording.listRecordings,
      payload
    );
    if (success) {
      if (!payload?.candidate) {
        const allRecordingPayload = {
          allRecordingList: data.results,
          allRecordingCurrentPage: data.page,
          allRecordingTotalResults: data.totalResults,
          allRecordingTotalPages: data.totalPages,
          allRecordingLoading: false,
        };

        yield put({
          type:
            data.page > 1
              ? actions.SET_ADD_ALL_RECORDING_LIST
              : actions.SET_STATE,
          payload: allRecordingPayload,
        });
      } else {
        const basePayload = {
          recordingsList: data.results,
          recordingTotalResults: data.totalResults,
          recordingTotalPages: data.totalPages,
          loading: false,
        };
        yield put({
          type: actions.SET_STATE,
          payload: basePayload,
        });
      }
    } else {
      yield handleApiError(status);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false, allRecordingLoading: false },
      });
    }
  } catch (e) {
    console.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false, allRecordingLoading: false },
    });
  }
}

function* GET_RECORDING(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data, status } = yield call(
      CallRecording.getRecordingById,
      payload
    );
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          currentRecording: data,
          loading: false,
        },
      });
    } else {
      yield handleApiError(status);
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
      message.error('Failed to fetch recording, please try again.');
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_LIST_RECORDINGS, LIST_RECORDINGS),
    takeEvery(actions.INVOKE_GET_RECORDINGS, GET_RECORDING),
  ]);
}
