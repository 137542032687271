export default {
  SET_STATE: 'applicants/SET_STATE',
  RESET_STATE: 'applicants/RESET_STATE',
  SET_MOVE_APPLICANT: 'applicants/SET_MOVE_APPLICANT',
  SET_ADD_TO_APPLICANT_LIST: 'applicants/SET_ADD_TO_APPLICANT_LIST',
  SET_MOVE_BULK_APPLICANT: 'applicants/SET_MOVE_BULK_APPLICANT',
  SET_UPDATE_APPLICANT_LIST: 'applicants/SET_UPDATE_APPLICANT_LIST',
  SET_CURRENT_STAGE_APPLICANTS: 'applicants/SET_CURRENT_STAGE_APPLICANTS',
  SET_CURRENT_APPLICANT: 'applicants/SET_CURRENT_APPLICANT',
  SET_CREATE_APPLICANT: 'applicants/SET_CREATE_APPLICANT',
  SET_DELETE_APPLICANT: 'applicants/SET_DELETE_APPLICANT',
  SET_UPDATE_APPLICANT: 'applicants/SET_UPDATE_APPLICANT',

  INVOKE_CREATE_APPLICANT: 'applicants/INVOKE_CREATE_APPLICANT',
  INVOKE_LIST_APPLICANTS: 'applicants/INVOKE_LIST_APPLICANTS',
  INVOKE_LIST_APPLICANTS_BY_STAGE_ID:
    'applicants/INVOKE_LIST_APPLICANTS_BY_STAGE_ID',
  INVOKE_GET_APPLICANT: 'applicants/INVOKE_GET_APPLICANT',
  INVOKE_GET_APPLICANT_ACTIVITY: 'applicants/INVOKE_GET_APPLICANT_ACTIVITY',
  INVOKE_UPDATE_APPLICANT: 'applicants/INVOKE_UPDATE_APPLICANT',
  INVOKE_MOVE_APPLICANT_BY_STAGE: 'applicants/INVOKE_MOVE_APPLICANT_BY_STAGE',
  INVOKE_UPDATE_APPLICANT_FORM_ENTRY:
    'applicants/INVOKE_UPDATE_APPLICANT_FORM_ENTRY',
  INVOKE_DELETE_APPLICANT: 'applicants/INVOKE_DELETE_APPLICANT',

  INVOKE_MOVE_BULK_APPLICANT_BY_STAGE:
    'applicants/INVOKE_MOVE_BULK_APPLICANT_BY_STAGE',
  INVOKE_PUSH_PAGE_ACTIVITY: 'applicants/PUSH_PAGE_ACTIVITY',

  INVOKE_LIST_ALL_APPLICANTS: 'applicants/INVOKE_LIST_ALL_APPLICANTS',
  SET_ADD_TO_ALL_APPLICANT_LIST: 'applicants/SET_ADD_TO_ALL_APPLICANT_LIST',
  INVOKE_GET_APPLICANT_ITEM: 'applicants/INVOKE_GET_APPLICANT_ITEM',

  INVOKE_SEARCH_CANDIDATES: 'applicants/INVOKE_SEARCH_CANDIDATES',

  INVOKE_CREATE_TAG: 'applicants/INVOKE_CREATE_TAG',
  INVOKE_LIST_TAGS: 'applicants/INVOKE_LIST_TAGS',
};
