import { PageHeader } from 'antd';
import React from 'react';
import { routes } from 'helpers/routes';
import { useHistory } from 'react-router-dom';
import MainHeader from 'components/MainHeader';

const NotificationLayout = ({ children }) => {
  const history = useHistory();

  return (
    <div className="vh-100">
      <MainHeader />
      <PageHeader
        className="site-page-header-responsive bg-light border"
        onBack={() => history.push(routes.DASHBOARD)}
        title={<p className="m-0 fs-6 fw-bold">Notification hub</p>}
        style={{
          padding: '0 15px',
        }}
        backIcon={<i className="bi bi-x-lg" />}
      />
      {children}
    </div>
  );
};

export default NotificationLayout;
