import { put } from 'redux-saga/effects';
import userActions from '../user/actions';

// handleApiError.js
export function* handleApiError(errorStatus) {
  if (errorStatus === 401 || errorStatus === 403) {
    yield put({
      type: userActions.LOGOUT,
    });
  } else {
    // handle other error types
  }
}
