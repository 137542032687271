import { message } from 'antd';
import { ArchiveReason } from 'apis/APISDK';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* LIST_ARCHIVE_REASONS(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(ArchiveReason.getArchiveReasons, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          archiveReasons: data?.results,
          totalResults: data?.totalResults,
          totalPages: data?.totalPages,
          loading: false,
        },
      });
    } else {
      message.error(resMessage);
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_ARCHIVE_REASON(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data } = yield call(ArchiveReason.getArchiveReason, {
      archiveReasonId: payload.archiveReasonId,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { current: data, loading: false },
      });
    } else {
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* UPDATE_ARCHIVE_REASON(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data } = yield call(
      ArchiveReason.updateArchiveReason,
      payload
    );
    if (success && data) {
      message.success('Archive reason updated');
      yield put({ type: actions.INVOKE_LIST_ARCHIVE_REASONS });
    } else message.error('Archive reason update failed');
  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* DELETE_ARCHIVE_REASON(payloads) {
  const { payload } = payloads;

  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { success } = yield call(ArchiveReason.deleteArchiveReason, payload);
    if (success) {
      message.success('Archive reason deleted');
      yield put({ type: actions.INVOKE_LIST_ARCHIVE_REASONS });
    } else message.error('Archive reason deletion failed');
  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* CREATE_ARCHIVE_REASON(payloads) {
  const { payload } = payloads;

  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      ArchiveReason.createArchiveReason,
      payload
    );
    if (success) {
      message.success('Archive reason created');
      yield put({
        type: actions.INVOKE_LIST_ARCHIVE_REASONS,
      });
    } else message.error(resMessage);
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_LIST_ARCHIVE_REASONS, LIST_ARCHIVE_REASONS),
    takeEvery(actions.INVOKE_GET_ARCHIVE_REASON, GET_ARCHIVE_REASON),
    takeEvery(actions.INVOKE_UPDATE_ARCHIVE_REASON, UPDATE_ARCHIVE_REASON),
    takeEvery(actions.INVOKE_DELETE_ARCHIVE_REASON, DELETE_ARCHIVE_REASON),
    takeEvery(actions.INVOKE_CREATE_ARCHIVE_REASON, CREATE_ARCHIVE_REASON),
  ]);
}
