import { message } from 'antd';
import { Archived } from 'apis/APISDK';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_ARCHIVED(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      Archived.createArchived,
      payload
    );

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      history.push(routes.USER_LIST);
      yield put({ type: actions.INVOKE_LIST_ARCHIVED });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_ARCHIVED() {
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const {
      data,
      message: resMsg,
      success,
    } = yield call(Archived.listArchived);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { userList: data?.results },
      });
    } else {
      message.error(resMsg);
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_ARCHIVED(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const { success, data } = yield call(Archived.getArchived, {
      id: payload.id,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false },
      });
      payload.next(data);
    } else {
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_ARCHIVED(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Archived.updateArchived, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      history.push(routes.USER_LIST);
      yield put({ type: actions.INVOKE_LIST_ARCHIVED });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* DELETE_ARCHIVED(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(Archived.deleteArchived, payload);
    yield put({ type: actions.SET_STATE, payload: { userList: [] } });
    yield put({ type: actions.INVOKE_LIST_ARCHIVED });
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_ARCHIVED,
      payload: { formType: payload.formType },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_ARCHIVED, CREATE_ARCHIVED),
    takeEvery(actions.INVOKE_LIST_ARCHIVED, LIST_ARCHIVED),
    takeEvery(actions.INVOKE_GET_ARCHIVED, GET_ARCHIVED),
    takeEvery(actions.INVOKE_UPDATE_ARCHIVED, UPDATE_ARCHIVED),
    takeEvery(actions.INVOKE_DELETE_ARCHIVED, DELETE_ARCHIVED),
  ]);
}
