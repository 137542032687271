import { PageHeader } from 'antd';
import React from 'react';
import MainHeader from 'components/MainHeader';
import './style.module.scss';
import { history } from 'helpers/history';

const ApplicantReviewsLayout = ({ children }) => {
  return (
    <div className="vh-100">
      <MainHeader />
      <PageHeader
        className="site-page-header-responsive bg-light border"
        onBack={() => history.goBack()}
        title={<p className="m-0 fs-6 fw-bold">Review</p>}
        style={{
          padding: '0 15px',
        }}
      />
      {children}
    </div>
  );
};

export default ApplicantReviewsLayout;
