import { message } from 'antd';
import { Rule, Workflow } from 'apis/APISDK';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_WORKFLOW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(Workflow.createWorkflow, payload);

    if (success) {
      yield put({
        type: actions.SET_ADD_NEW_WORKFLOW,
        payload: { data },
      });
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* LIST_WORKFLOWS(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const {
      data,
      success,
      message: resMessage,
    } = yield call(Workflow.listWorkflows, payload);
    if (data && success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          workflowList: data.results,
          loading: false,
          workflowTotalResults: data.totalResults,
          workflowTotalPages: data.totalPages,
        },
      });
    } else {
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_WORKFLOW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Workflow.getWorkflowById, {
      id: payload.id,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false, currentWorkflow: data },
      });
    } else {
      message.error(resMessage);
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_WORKFLOW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Workflow.updateWorkflow, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
      history.push(routes.WORKFLOW_LIST);
      yield put({
        type: actions.INVOKE_GET_WORKFLOW,
        payload: {
          id: payload.id,
        },
      });
      yield put({
        type: actions.INVOKE_LIST_WORKFLOWS,
      });
      if (payload?.next) payload.next();
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* UPDATE_WORKFLOW_STAGES(payloads) {
  const { payload } = payloads;

  const workflows = yield select((state) => state.workflows);
  const currentWorkflow = workflows?.currentWorkflow;
  let stages = [];
  if (currentWorkflow?.stages) {
    const archivedStages = [];
    const nonArchivedstages = [];
    currentWorkflow?.stages?.forEach((stage) => {
      if (stage.isArchiveStage) archivedStages.push(stage.id);
      else nonArchivedstages.push(stage.id);
    });

    stages = [...nonArchivedstages, ...payload.stages, ...archivedStages];
  } else {
    stages = payload.stages;
  }
  yield put({
    type: actions.INVOKE_UPDATE_WORKFLOW,
    payload: {
      id: payload.id,
      data: {
        stages,
      },
    },
  });
}

function* UPDATE_WORKFLOW_RULES(payloads) {
  const { payload } = payloads;

  const workflows = yield select((state) => state.workflows);
  const currentWorkflow = workflows?.currentWorkflow;
  let rules = [];
  if (currentWorkflow?.rules) {
    const RuleIds = currentWorkflow.rules.map((rule) => rule.id);
    rules = [...RuleIds, ...payload.rules];
  } else {
    rules = payload.rules;
  }
  yield put({
    type: actions.INVOKE_UPDATE_WORKFLOW,
    payload: {
      id: payload.id,
      data: {
        rules,
      },
    },
  });
}

function* DELETE_WORKFLOW(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const {
      data,
      success,
      message: resMessage,
    } = yield call(Workflow.deleteWorkflow, payload);
    if (success && data) {
      yield put({
        type: actions.INVOKE_LIST_WORKFLOWS,
      });
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_WORKFLOWS,
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* CREATE_WORKFLOW_TEMPLATE_RULE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(Rule.createRule, payload);

    if (success) {
      if (payload.jobId) {
        yield put({
          type: actions.INVOKE_LIST_WORKFLOW_TEMPLATE_RULE,
          payload: {
            type: data?.type,
            job: payload.jobId,
          },
        });
      } else if (data?.id) {
        yield put({
          type: actions.INVOKE_UPDATE_WORKFLOW_RULES,
          payload: {
            rules: [data.id],
            id: payload.workflowId,
          },
        });
      }
      if (payload?.next) payload.next();
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* UPDATE_WORKFLOW_TEMPLATE_RULE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      Rule.updateRule,
      payload
    );

    if (success) {
      if (payload.workflowId)
        yield put({
          type: actions.INVOKE_GET_WORKFLOW,
          payload: {
            id: payload?.workflowId,
          },
        });
      if (payload.jobId) {
        yield put({
          type: actions.INVOKE_LIST_WORKFLOW_TEMPLATE_RULE,
          payload: {
            job: payload.jobId,
            type: payload.type,
          },
        });
      }
      if (payload?.next) payload.next();
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* DELETE_WORKFLOW_TEMPLATE_RULE(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(Rule.deleteRule, payload);
    if (payload.jobId) {
      yield put({
        type: actions.INVOKE_LIST_WORKFLOW_TEMPLATE_RULE,
        payload: {
          job: payload.jobId,
          type: payload.type,
        },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_WORKFLOWS,
      payload: {
        id: payload.candidate,
      },
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* LIST_WORKFLOW_TEMPLATE_RULE(payloads) {
  const { payload = {} } = payloads;
  if (!payload.searchTerm) {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true },
    });
  }
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(Rule.listRules, payload);

    if (success) {
      const update = {};
      if (payload.searchTerm) update.searchList = data?.results;
      else update.rulesList = data?.results;
      yield put({
        type: actions.SET_STATE,
        payload: update,
      });
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_WORKFLOW, CREATE_WORKFLOW),
    takeEvery(actions.INVOKE_LIST_WORKFLOWS, LIST_WORKFLOWS),
    takeEvery(actions.INVOKE_GET_WORKFLOW, GET_WORKFLOW),
    takeEvery(actions.INVOKE_UPDATE_WORKFLOW, UPDATE_WORKFLOW),
    takeEvery(actions.INVOKE_UPDATE_WORKFLOW_STAGES, UPDATE_WORKFLOW_STAGES),
    takeEvery(actions.INVOKE_DELETE_WORKFLOW, DELETE_WORKFLOW),
    takeEvery(
      actions.INVOKE_CREATE_WORKFLOW_TEMPLATE_RULE,
      CREATE_WORKFLOW_TEMPLATE_RULE
    ),
    takeEvery(
      actions.INVOKE_UPDATE_WORKFLOW_TEMPLATE_RULE,
      UPDATE_WORKFLOW_TEMPLATE_RULE
    ),
    takeEvery(
      actions.INVOKE_DELETE_WORKFLOW_TEMPLATE_RULE,
      DELETE_WORKFLOW_TEMPLATE_RULE
    ),
    takeEvery(
      actions.INVOKE_LIST_WORKFLOW_TEMPLATE_RULE,
      LIST_WORKFLOW_TEMPLATE_RULE
    ),

    takeEvery(actions.INVOKE_UPDATE_WORKFLOW_RULES, UPDATE_WORKFLOW_RULES),
  ]);
}
