import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Tag, Modal, Tabs, List } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from 'redux/applicants/actions';
import { debounce } from 'lodash';
import { routes } from 'helpers/routes';
import { history } from 'helpers/history';
import { APPLICANT_PAGE_LIMIT } from 'helpers/constants';
import { useQuery } from 'hooks/useQuery';
import './styles.css';

const { TabPane } = Tabs;

const tagStyleP = {
  maxWidth: '100px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
};

const tagStyle = {
  display: 'flex',
  alignItems: 'center',
  background: '#000000',
  color: 'white',
  padding: '4px 8px',
  borderRadius: '4px',
  marginRight: '8px',
  fontSize: '12px',
};

const SearchBar = ({
  currentStage,
  stages,
  searchApplicants,
  searchList,
  currentJob,
}) => {
  const queryParams = useQuery();

  const [stageTag, setStageTag] = useState({});
  const [jobTag, setJobTag] = useState({});
  const [inputValue, setInputValue] = useState(queryParams.searchTerm || '');
  const [modalVisible, setModalVisible] = useState(false);
  const [hasResults, setHasResults] = useState(false);

  const inputRef = useRef(null);

  const focusInput = useCallback(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, []);

  const handleModalOpen = useCallback(() => {
    setModalVisible(true);
    focusInput();
  }, [focusInput]);

  const handleModalClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleItemClick = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (modalVisible) {
      focusInput();
    }
  }, [modalVisible, focusInput]);

  const debouncedSearch = useCallback(
    debounce((value, jobId, stageId) => {
      if (value !== '') {
        searchApplicants(value, jobId, stageId);
      }
    }, 500),
    [searchApplicants]
  );

  useEffect(() => {
    setHasResults(searchList.length > 0);
  }, [searchList]);

  const updateJobTag = (currentJob) => {
    if (currentJob && Object.values(currentJob).length > 0) {
      setJobTag({
        value: `Job: ${currentJob.title}`,
        id: currentJob.id,
      });
    } else {
      setJobTag({});
    }
  };

  const updateStageTag = (currentStage, stages) => {
    if (currentStage) {
      const stageName = stages.find(
        (element) => element.id === currentStage
      )?.name;
      if (stageName) {
        setStageTag({
          value: `Stage: ${
            stageName.charAt(0).toUpperCase() + stageName.slice(1).toLowerCase()
          }`,
          id: currentStage,
        });
      }
    } else {
      setStageTag({});
    }
  };

  useEffect(() => {
    updateJobTag(currentJob);
  }, [currentJob]);

  useEffect(() => {
    updateStageTag(currentStage, stages);
  }, [currentStage, stages]);

  useEffect(() => {
    if (modalVisible) {
      debouncedSearch(inputValue, jobTag.id, stageTag.id);
    }
  }, [inputValue, jobTag.id, stageTag.id, modalVisible, debouncedSearch]);

  const handleSearch = () => {
    history.push(`/search?searchTerm=${inputValue}`);
    setModalVisible(false);
  };

  const renderSearchInput = () => (
    <Input
      size="large"
      placeholder="Search Candidates"
      allowClear
      ref={inputRef}
      prefix={
        <>
          {jobTag?.value && (
            <Tag
              className="flex justify-center"
              key={jobTag.id}
              closable
              style={tagStyle}
              onClose={() => {
                setJobTag({});
              }}
              color="white"
            >
              <p className="m-0" style={tagStyleP}>
                {jobTag.value}
              </p>
            </Tag>
          )}
          {stageTag?.value && (
            <Tag
              key={stageTag.id}
              style={tagStyle}
              closable
              onClose={() => setStageTag({})}
              color="white"
            >
              <p className="m-0" style={tagStyleP}>
                {stageTag.value}
              </p>
            </Tag>
          )}
        </>
      }
      suffix={
        <Button
          type="primary"
          onClick={handleSearch}
          style={{ height: '100%' }}
        >
          <i className="bi bi-search me-2" />
          Search
        </Button>
      }
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );

  return (
    <div style={{ width: '50%' }}>
      <Input
        size="large"
        placeholder="Search"
        onClick={() => {
          handleModalOpen();
          updateJobTag(currentJob);
          updateStageTag(currentStage, stages);
          setHasResults(false);
        }}
        readOnly
        suffix={
          <i
            className="bi bi-search"
            style={{ fontSize: '16px', color: '#8c8c8c' }}
          />
        }
      />
      <Modal
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="80%"
        className="search-modal"
        closable={false}
      >
        <div className="search-modal-content">
          {renderSearchInput()}
          {hasResults && (
            <Tabs defaultActiveKey="1" className="mt-4 search-tabs">
              <TabPane tab="Candidates" key="1">
                <List
                  itemLayout="horizontal"
                  defaultActiveFirstOption
                  dataSource={searchList}
                  renderItem={(item) => (
                    <Link
                      to={routes.JOB_APPLICANT_OVERVIEW(
                        item.job.id,
                        item.stage.id,
                        item.id
                      )}
                      onClick={handleItemClick}
                    >
                      <List.Item
                        key={item.id}
                        className="border-bottom bg-white px-3"
                      >
                        <List.Item.Meta
                          title={
                            <>
                              {item.name}
                              {' - '}
                              <span className="text-muted">{item.email}</span>
                            </>
                          }
                          description={item.job.title}
                        />
                        <Tag color="red">{item.stage.name.toUpperCase()}</Tag>
                      </List.Item>
                    </Link>
                  )}
                />
              </TabPane>
              <TabPane tab="AI (Coming Soon)" key="2" disabled>
                Coming Soon
              </TabPane>
            </Tabs>
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ jobs, applicants }) => {
  return {
    currentJob: jobs.current,
    currentStage: applicants.currentStage,
    searchList: applicants.searchList,
    searchListLoading: applicants.searchListLoading,
    stages: jobs.jobListStages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchApplicants: (
      searchTerm,
      jobId,
      stageId,
      limit = APPLICANT_PAGE_LIMIT
    ) =>
      dispatch({
        type: actions.INVOKE_SEARCH_CANDIDATES,
        payload: {
          jobId,
          currentStage: stageId,
          limit,
          searchTerm,
        },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
