import React from 'react';
import MainHeader from 'components/MainHeader';
import { connect } from 'react-redux';
import UpgradePlanPopup from 'components/UpgradePlanPopup';

const JobLayout = ({ children, isPro = false }) => {
  return (
    <div className="vh-100">
      <MainHeader />

      {children}
      {!isPro && <UpgradePlanPopup />}
    </div>
  );
};

const mapStateToProps = ({ jobs, user }) => {
  return { jobFilter: jobs.filter, isPro: user.organization?.plan !== 'free' };
};
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobLayout);
