import { Candidate } from 'apis/APISDK';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* LIST_CANDIDATES(payloads) {
  const { payload } = payloads;

  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(Candidate.listCandidates, {
      searchTerm: payload.searchTerm,
      page: payload.page,
    });
    if (data.page === 1)
      yield put({
        type: actions.SET_STATE,
        payload: { candidatesList: data.results },
      });
    else
      yield put({
        type: actions.SET_ADD_CANDIDATES,
        payload: { candidatesList: data.results },
      });
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        totalPages: data.totalPages,
        page: data.page,
      },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export default function* contentsSaga() {
  yield all([takeEvery(actions.INVOKE_LIST_CANDIDATES, LIST_CANDIDATES)]);
}
