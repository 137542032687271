import actions from './actions';

const initialState = {
  list: [],
  current: null,
  emailDomainStatus: false,
  loading: false,
  formLoading: false,
  formSubmitting: false,
};

const updateObjectByKey = (arr, id, keyToUpdate, newVal, key = 'id') => {
  const obj = arr.find((x) => x[key] === id);
  if (obj) {
    obj[keyToUpdate] = newVal;
  }

  return arr;
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SET_DOMAIN_ID_STATUS: {
      const { list: domainList } = state;

      return {
        ...state,
        list: [
          ...updateObjectByKey(
            domainList,
            action.payload.id,
            'verified',
            action.payload.domainStatus
          ),
        ],
      };
    }
    case actions.SET_DOMAIN_ID_LOADING: {
      const { list: domainList } = state;

      return {
        ...state,
        list: [
          ...updateObjectByKey(
            domainList,
            action.payload.id,
            'loading',
            action.payload.loading
          ),
        ],
      };
    }
    case actions.SET_DELETE_DOMAIN_ID: {
      const { list: domainList } = state;
      domainList.splice(
        domainList.findIndex((i) => {
          return i.id === action.payload.id;
        }),
        1
      );

      return {
        ...state,
        list: [...domainList],
      };
    }
    default:
      return state;
  }
}
