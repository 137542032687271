export const projectName = [
  'typesense',
  'mobsf',
  'docz',
  'hoppscotch',
  'chatwoot',
];

export const contentStatus = [
  'unassigned',
  'pending-assigned-to',
  'assigned-to',
  'draft',
  'tech-review-pending',
  'tech-review',
  'editorial-review-pending',
  'banner-pending',
  'editorial-review',
  'rework',
  'pre-publish',
  'publish',
  'reopen',
];

export const JobEmploymentTypeLabel = {
  fullTime: 'Full-time',
  partTime: 'Part-time',
  temporary: 'Temporary',
  contract: 'Contract',
  internship: 'Internship',
  volunteer: 'Volunteer',
  other: 'Other',
};

export const JobEmploymentType = {
  fullTime: 'fullTime',
  partTime: 'partTime',
  temporary: 'temporary',
  contract: 'contract',
  internship: 'internship',
  volunteer: 'volunteer',
  other: 'other',
};

export const JobRemoteOption = {
  noRemote: 'noRemote',
  remoteFriendly: 'remoteFriendly',
  remoteOnly: 'remoteOnly',
};

export const JobRemoteOptionLabel = {
  noRemote: 'No Remote',
  remoteFriendly: 'Remote Friendly',
  remoteOnly: 'Remote (Only)',
};

export const JobSalaryOption = {
  notShown: 'notShown',
  fixedAmount: 'fixedAmount',
  range: 'range',
};

export const JobPostSocialMediaOption = {
  default: 'default',
  custom: 'custom',
  none: 'none',
};

export const DEFAULT_FIELD_TYPES = {
  text: 'text',
  email: 'email',
  number: 'number',
  mobile: 'mobile',
  currency: 'currency',
  location: 'location',
  url: 'url',
  multipleChoice: 'multipleChoice',
  yesNo: 'yesNo',
  fileUpload: 'fileUpload',
  textArea: 'textArea',
};

export const REJECTION_RULE_CONDITIONS = {
  EQUALS: 'equals',
  NOT_EQUALS: 'notEquals',
  GREATER_THAN: 'greaterThan',
  LESS_THAN: 'lessThan',
  CODE_EQUALS: 'codeEquals',
  CODE_NOT_EQUALS: 'codeNotEquals',
};

export const REJECTION_RULE_CONDITIONS_LABEL = {
  [REJECTION_RULE_CONDITIONS.EQUALS]: 'Equals',
  [REJECTION_RULE_CONDITIONS.NOT_EQUALS]: 'Not Equals',
  [REJECTION_RULE_CONDITIONS.GREATER_THAN]: 'Greater Than',
  [REJECTION_RULE_CONDITIONS.LESS_THAN]: 'Less Than',
  [REJECTION_RULE_CONDITIONS.CODE_EQUALS]: 'Country Code Equals',
  [REJECTION_RULE_CONDITIONS.CODE_NOT_EQUALS]: 'Country Code Not Equals',
};

export const KNOWN_FIELD_NAMES = {
  name: 'name',
  email: 'email',
  mobile: 'mobile',
  location: 'location',
  totalExperience: 'totalExperience',
  linkedin: 'linkedin',
  facebook: 'facebook',
  twitter: 'twitter',
  github: 'github',
  dribble: 'dribble',
  websiteUrl: 'websiteUrl',
  resume: 'resume',
  noticePeriod: 'noticePeriod',
  currentCTC: 'currentCTC',
  expectedCTC: 'expectedCTC',
};

export const KnownFieldNameLabel = {
  [KNOWN_FIELD_NAMES.name]: 'Name',
  [KNOWN_FIELD_NAMES.mobile]: 'Mobile',
  [KNOWN_FIELD_NAMES.email]: 'Email',
  [KNOWN_FIELD_NAMES.resume]: 'Resume',
  [KNOWN_FIELD_NAMES.totalExperience]: 'Total Experience',
  [KNOWN_FIELD_NAMES.linkedin]: 'Linkedin',
  [KNOWN_FIELD_NAMES.github]: 'Github',
  [KNOWN_FIELD_NAMES.facebook]: 'Facebook',
  [KNOWN_FIELD_NAMES.twitter]: 'Twitter',
  [KNOWN_FIELD_NAMES.dribble]: 'Dribble',
  [KNOWN_FIELD_NAMES.websiteUrl]: 'Website',
  [KNOWN_FIELD_NAMES.noticePeriod]: 'NoticePeriod',
  [KNOWN_FIELD_NAMES.currentCTC]: 'Current CTC',
  [KNOWN_FIELD_NAMES.expectedCTC]: 'Expected CTC',
  [KNOWN_FIELD_NAMES.location]: 'Location',
};

export const CompulsoryFields = [
  KNOWN_FIELD_NAMES.resume,
  KNOWN_FIELD_NAMES.name,
  KNOWN_FIELD_NAMES.email,
  KNOWN_FIELD_NAMES.mobile,
];

export const FIELD_CATEGORIES = {
  personalInfo: 'personalInfo',
  workInfo: 'workInfo',
  salaryInfo: 'salaryInfo',
  socialLinks: 'socialLinks',
  files: 'files',
  others: 'others',
};

export const KnownApplicantFields = {
  name: 'name',
  email: 'email',
  mobile: 'mobile',
  location: 'location',
  linkedin: 'linkedin',
  facebook: 'facebook',
  twitter: 'twitter',
  github: 'github',
  dribbble: 'dribbble',
  websiteUrl: 'websiteUrl',
  source: 'source',
  resume: 'resume',
};

export const TextUniqueInputs = [
  KNOWN_FIELD_NAMES.name,
  KNOWN_FIELD_NAMES.location,
];
export const UrlUniqueInputs = [
  KNOWN_FIELD_NAMES.linkedin,
  KNOWN_FIELD_NAMES.github,
];

export const FileUniqueInputs = [
  KNOWN_FIELD_NAMES.fileUpload,
  KNOWN_FIELD_NAMES.resume,
];

export const APPLICANT_PAGE_LIMIT = 10;
export const ALL_APPLICANT_PAGE_LIMIT = 10;
export const JOB_PAGE_LIMIT = 10;
export const PORTAL_JOB_PAGE_LIMIT = 15;
export const REVIEWS_PAGE_LIMIT = 10;
export const TEAM_INVITE_PAGE_LIMIT = 5;
export const CALL_RECORDINGS_LIMIT = 10;
export const WORKFLOWS_PAGE_LIMIT = 10;

export const DEFAULT_PAGE_LIMIT = 10;

export const DROPDOWN_LIMIT = 10;

export const JOB_STATUS_TYPE = {
  active: 'active',
  inactive: 'inactive',
  archived: 'archived',
};

export const JOB_APPLICATION_SUCCESS_MESSAGE =
  'Application submitted successfully!🎉';

export const JOB_ALREADY_SUBMITTED_MESSAGE = 'Job already applied.';

export const USER_ROLES = {
  user: 'user',
  orgAdmin: 'orgAdmin',
  interviewer: 'interviewer',
};

export const USER_ROLES_LABEL = {
  [USER_ROLES.user]: 'Member',
  [USER_ROLES.orgAdmin]: 'Admin',
  [USER_ROLES.interviewer]: 'Interviewer',
};

export const APPLICANT_DETAIL_MENUS = {
  overview: 'overview',
  resume: 'resume',
  messages: 'messages',
  files: 'files',
  privateNotes: 'notes',
  tags: 'tags',
  interview: 'interview',
  recordings: 'recordings',
  additionalForm: 'form-entries',
};

export const RESTRICTED_APPLICANT_MENUS = {
  [USER_ROLES.interviewer]: [
    APPLICANT_DETAIL_MENUS.messages,
    APPLICANT_DETAIL_MENUS.interview,
  ],
};

export const MESSAGE_TEMPLATE_VARIBLES = {
  candidateName: '[candidateName]',
  jobTitle: '[jobTitle]',
  orgName: '[orgName]',
  senderName: '[senderName]',
  senderEmail: '[senderEmail]',
  userTitle: '[userTitle]',
};

export const INVITE_STATUS_TYPE = {
  pending: 'pending',
  accepted: 'accepted',
  deleted: 'deleted',
};

export const KNOWN_TALENT_APPLICANT_KEYS = {
  name: 'name',
  email: 'email',
  mobile: 'mobile',
  location: 'location',
  linkedin: 'linkedin',
  facebook: 'facebook',
  twitter: 'twitter',
  github: 'github',
  dribbble: 'dribbble',
  websiteUrl: 'websiteUrl',
  formData: 'formData',
  resume: 'resume',
  files: 'files',
  receiveMarketingEmails: 'receiveMarketingEmails',
};

export const EXTERNAL_APPLICANT_FIELDS = {
  resume: 'resume',
  source: 'source',
  receiveMarketingEmails: 'receiveMarketingEmails',
  name: 'name',
  email: 'email',
  mobile: 'mobile',
  location: 'location',
  linkedin: 'linkedin',
  facebook: 'facebook',
  twitter: 'twitter',
  github: 'github',
  dribbble: 'dribbble',
  websiteUrl: 'websiteUrl',
  expectedCTC: 'expectedCTC',
  currentCTC: 'currentCTC',
  noticePeriod: 'noticePeriod',
  totalExperience: 'totalExperience',
};

export const SUBSCRIPTION_PLANS = {
  free: 'free',
  plan1: 'prime',
  plan2: 'aiPrime',
};

export const AIWorkerTypes = {
  screeningRecruiter: 'screeningRecruiter',
  hrRecruiter: 'hrRecruiter',
  communicationRecruiter: 'communicationRecruiter',
  onboardingSpecialist: 'onboardingSpecialist',
};

export const AIWorkerTypesLabel = {
  screeningRecruiter: 'Screening Recruiter',
  hrRecruiter: 'HR Recuiter',
  communicationRecruiter: 'Communication Recruiter',
  onboardingSpecialist: 'Onboarding Specialist',
};

export const VIEW_CONTROL = {
  ready: 'ready',
  hide: 'hide',
  disabled: 'disabled',
  comingSoon: 'coming_soon',
};
export const PDF_EDITOR_BUTTONS = {
  home: VIEW_CONTROL.ready,
  back: VIEW_CONTROL.hide,
  thumbnail: VIEW_CONTROL.ready,
  search: VIEW_CONTROL.ready,
  nameInput: VIEW_CONTROL.ready,
  share: VIEW_CONTROL.ready,
  auth: VIEW_CONTROL.hide,
  print: VIEW_CONTROL.ready,
  save: VIEW_CONTROL.ready,
  draw: VIEW_CONTROL.ready,
  sidebarOpen: VIEW_CONTROL.ready,
  export: VIEW_CONTROL.ready,
  goToTop: VIEW_CONTROL.ready,
  goToLast: VIEW_CONTROL.ready,
  viewEdit: VIEW_CONTROL.ready,
  annotations: VIEW_CONTROL.ready,
  insert: VIEW_CONTROL.ready,
  mergePdf: VIEW_CONTROL.comingSoon,
  compressPdf: VIEW_CONTROL.comingSoon,
  summary: VIEW_CONTROL.ready,
  fillAndSign: VIEW_CONTROL.ready,
  organise: VIEW_CONTROL.comingSoon,
  comment: VIEW_CONTROL.ready,
  properties: VIEW_CONTROL.ready,
  waitlist: VIEW_CONTROL.ready,
};

export const RULE_TYPES = {
  AI_CALL: 'aiCall',
  TEMPLATE: 'template',
  REJECTION_RULE: 'rejectionRule',
};
export const USER_ACTIVITY_ACTIONS = {
  APPLIED: 'applied',
  ADDED: 'added',
  MOVED: 'moved',
  INVITED_INTERVIEW: 'invitedInterview',
  ACCEPTED_INTERVIEW: 'acceptedInterview',
  CANCELED_INTERVIEW: 'canceledInterview',
  RESCHEDULED_INTERVIEW: 'rescheduledInterview',
  STARTED_INTERVIEW: 'startedInterview',
  ENDED_INTERVIEW: 'endedInterview',
  ADDED_COMMENT: 'addedComment',
  STARTED_REVIEW: 'startedReview',
  EDITED: 'edited',
  DELETED: 'deleted',
  SENT_MESSAGE: 'sentMessage',
  UPDATED_TAGS: 'updatedTags',
  UPDATED_PRIVATE_NOTE: 'updatedPrivateNote',
  UPDATED_RESUME: 'updatedResume',
  UPDATED_FILES: 'updatedFiles',
  ARCHIVED: 'archived',
  IMPORT_CSV: 'importCSV',
  SELECTED_FROM_TALENT_COMMUNITY: 'selectedFromTalentCommunity',
};

export const USER_TRIGGER_EVENTS = {
  [USER_ACTIVITY_ACTIONS.APPLIED]: 'On candidate applies to job',
  [USER_ACTIVITY_ACTIONS.MOVED]: 'On candidate moves to new stage',
  [USER_ACTIVITY_ACTIONS.ARCHIVED]: 'On candidate is archived',
  [USER_ACTIVITY_ACTIONS.INVITED_INTERVIEW]:
    'On candidate is invited to interview',
  [USER_ACTIVITY_ACTIONS.SELECTED_FROM_TALENT_COMMUNITY]:
    'On candidate is selected from talent pool',
};

export const CALL_STATUS = {
  inQueue: 'inQueue',
  ringing: 'ringing',
  inProgress: 'inProgress',
  completed: 'completed',
  failed: 'failed',
  init: 'init',
};

export const FORM_TYPE = {
  job: 'jobForm',
  custom: 'custom',
};

export const TEMPLATE_TYPE = {
  email: 'Email',
  slack: 'Slack message',
};

export const MESSAGE_TYPE = {
  receive: 'receive',
  send: 'send',
};

export const MESSAGE_CATEGORY_TYPE = {
  inbox: MESSAGE_TYPE.receive,
  sent: MESSAGE_TYPE.send,
};

export const FILTER_OPERATIONS = {
  GREATER_THAN: 'Greater than a specific number.',
  LESS_THAN: 'Less than a specific number.',
  EQUAL_TO: 'Exactly matches a specific value.',
  RANGE: 'Falls between two specified numbers.',
  CONTAINS: 'Includes a specific word or phrase.',
  DOES_NOT_CONTAIN: 'Excludes a specific word or phrase.',
  EXACT_MATCH: 'Identical to a specific value.',
  MULTIPLE_VALUES: 'Matches any one of several options.',
  REGEX: 'Follows a specific pattern.',
  YES_NO: 'A simple yes or no answer.',
  IS_ONE_OF: 'Matches any value from a predefined list.',
  IS_NOT_ONE_OF: 'Does not match any value from a predefined list.',
  CONTAINS_ANY: 'Includes at least one of the specified items.',
  CONTAINS_ALL: 'Includes all specified items.',
  EXISTS: 'The field is present.',
  NOT_EXISTS: 'The field is absent.',
};

export const FILTERS = {
  GREATER_THAN: 'gt:',
  LESS_THAN: 'lt:',
  EQUAL_TO: 'eq:',
  NOT_EQUAL: 'ne:',
  RANGE: 'range:',
  REGEX: 'regex:',
  CONTAINS: 'contains:',
  NOT_CONTAINS: 'notcontains:',
  IS_ONE_OF: 'in:',
  IS_NOT_ONE_OF: 'nin:',
  CONTAINS_ANY: 'any:',
  CONTAINS_ALL: 'all:',
  EXISTS: 'exists',
  NOT_EXISTS: 'notexists',
  YES: 'yes',
  NO: 'no',
};
