export default {
  AF: {
    name: 'Afghanistan',
    currency: 'AFN',
    symbol: '؋',
  },
  AL: {
    name: 'Albania',
    currency: 'ALL',
    symbol: 'L',
  },
  DZ: {
    name: 'Algeria',
    currency: 'DZD',
    symbol: 'دج',
  },
  AS: {
    name: 'American Samoa',
    currency: 'USD',
    symbol: '$',
  },
  AD: {
    name: 'Andorra',
    currency: 'EUR',
    symbol: '€',
  },
  AO: {
    name: 'Angola',
    currency: '',
    symbol: '',
  },
  AI: {
    name: 'Anguilla',
    currency: 'XCD',
    symbol: '$',
  },
  AG: {
    name: 'Antigua and Barbuda',
    currency: 'XCD',
    symbol: '$',
  },
  AR: {
    name: 'Argentina',
    currency: 'ARS',
    symbol: '$',
  },
  AM: {
    name: 'Armenia',
    currency: 'AMD',
    symbol: '֏',
  },
  AW: {
    name: 'Aruba',
    currency: 'AWG',
    symbol: 'ƒ',
  },
  AU: {
    name: 'Australia',
    currency: 'AUD',
    symbol: '$',
  },
  AT: {
    name: 'Austria',
    currency: 'EUR',
    symbol: '€',
  },
  AZ: {
    name: 'Azerbaijan',
    currency: 'AZN',
    symbol: '₼',
  },
  BS: {
    name: 'Bahamas',
    currency: 'BSD',
    symbol: '$',
  },
  BH: {
    name: 'Bahrain',
    currency: 'BHD',
    symbol: '.د.ب',
  },
  BD: {
    name: 'Bangladesh',
    currency: 'BDT',
    symbol: '৳',
  },
  BB: {
    name: 'Barbados',
    currency: 'BBD',
    symbol: '$',
  },
  BY: {
    name: 'Belarus',
    currency: 'BYN',
    symbol: 'Br',
  },
  BE: {
    name: 'Belgium',
    currency: 'EUR',
    symbol: '€',
  },
  BZ: {
    name: 'Belize',
    currency: 'BZD',
    symbol: 'BZ$',
  },
  BJ: {
    name: 'Benin',
    currency: 'XOF',
    symbol: 'CFA',
  },
  BM: {
    name: 'Bermuda',
    currency: 'BMD',
    symbol: '$',
  },
  BT: {
    name: 'Bhutan',
    currency: 'BTN',
    symbol: 'Nu.',
  },
  BO: {
    name: 'Bolivia, Plurinational State of',
    currency: 'BOB',
    symbol: '$b',
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    currency: 'BAM',
    symbol: 'KM',
  },
  BW: {
    name: 'Botswana',
    currency: 'BWP',
    symbol: 'P',
  },
  BR: {
    name: 'Brazil',
    currency: 'BRL',
    symbol: 'R$',
  },
  IO: {
    name: 'British Indian Ocean Territory',
    currency: 'USD',
    symbol: '$',
  },
  BG: {
    name: 'Bulgaria',
    currency: 'BGN',
    symbol: 'лв',
  },
  BF: {
    name: 'Burkina Faso',
    currency: 'XOF',
    symbol: 'CFA',
  },
  BI: {
    name: 'Burundi',
    currency: 'BIF',
    symbol: 'FBu',
  },
  KH: {
    name: 'Cambodia',
    currency: 'KHR',
    symbol: '៛',
  },
  CM: {
    name: 'Cameroon',
    currency: 'XAF',
    symbol: 'FCFA',
  },
  CA: {
    name: 'Canada',
    currency: 'CAD',
    symbol: '$',
  },
  CV: {
    name: 'Cape Verde',
    currency: 'CVE',
    symbol: '$',
  },
  KY: {
    name: 'Cayman Islands',
    currency: 'KYD',
    symbol: '$',
  },
  CF: {
    name: 'Central African Republic',
    currency: 'XAF',
    symbol: 'FCFA',
  },
  TD: {
    name: 'Chad',
    currency: 'XAF',
    symbol: 'FCFA',
  },
  CL: {
    name: 'Chile',
    currency: 'CLP',
    symbol: '$',
  },
  CN: {
    name: 'China',
    currency: 'CNY',
    symbol: '¥',
  },
  CO: {
    name: 'Colombia',
    currency: 'COP',
    symbol: '$',
  },
  KM: {
    name: 'Comoros',
    currency: 'KMF',
    symbol: 'CF',
  },
  CG: {
    name: 'Congo',
    currency: 'XAF',
    symbol: 'FCFA',
  },
  CD: {
    name: 'Democratic Republic of the Congo',
    currency: 'CDF',
    symbol: 'FC',
  },
  CK: {
    name: 'Cook Islands',
    currency: 'NZD',
    symbol: '$',
  },
  CR: {
    name: 'Costa Rica',
    currency: 'CRC',
    symbol: '₡',
  },
  CI: {
    name: "Côte d'Ivoire",
    currency: 'XOF',
    symbol: 'CFA',
  },
  HR: {
    name: 'Croatia',
    currency: 'HRK',
    symbol: 'kn',
  },
  CU: {
    name: 'Cuba',
    currency: 'CUP',
    symbol: '₱',
  },
  CW: {
    name: 'Curaçao',
    currency: 'ANG',
    symbol: 'ƒ',
  },
  CY: {
    name: 'Cyprus',
    currency: 'EUR',
    symbol: '€',
  },
  CZ: {
    name: 'Czech Republic',
    currency: 'CZK',
    symbol: 'Kč',
  },
  DK: {
    name: 'Denmark',
    currency: 'DKK',
    symbol: 'kr',
  },
  DJ: {
    name: 'Djibouti',
    currency: 'DJF',
    symbol: 'Fdj',
  },
  DM: {
    name: 'Dominica',
    currency: 'XCD',
    symbol: '$',
  },
  DO: {
    name: 'Dominican Republic',
    currency: 'DOP',
    symbol: 'RD$',
  },
  EC: {
    name: 'Ecuador',
    currency: 'USD',
    symbol: '$',
  },
  EG: {
    name: 'Egypt',
    currency: 'EGP',
    symbol: '£',
  },
  SV: {
    name: 'El Salvador',
    currency: 'SVC',
    symbol: '$',
  },
  GQ: {
    name: 'Equatorial Guinea',
    currency: 'XAF',
    symbol: 'FCFA',
  },
  ER: {
    name: 'Eritrea',
    currency: 'ERN',
    symbol: 'Nfk',
  },
  EE: {
    name: 'Estonia',
    currency: 'EUR',
    symbol: '€',
  },
  ET: {
    name: 'Ethiopia',
    currency: 'ETB',
    symbol: 'Br',
  },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    currency: 'FKP',
    symbol: '£',
  },
  FO: {
    name: 'Faroe Islands',
    currency: 'DKK',
    symbol: 'kr',
  },
  FJ: {
    name: 'Fiji',
    currency: 'FJD',
    symbol: '$',
  },
  FI: {
    name: 'Finland',
    currency: 'EUR',
    symbol: '€',
  },
  FR: {
    name: 'France',
    currency: 'EUR',
    symbol: '€',
  },
  PF: {
    name: 'French Polynesia',
    currency: 'XPF',
    symbol: '₣',
  },
  GA: {
    name: 'Gabon',
    currency: 'XAF',
    symbol: 'FCFA',
  },
  GM: {
    name: 'Gambia',
    currency: 'GMD',
    symbol: 'D',
  },
  GE: {
    name: 'Georgia',
    currency: 'GEL',
    symbol: '₾',
  },
  DE: {
    name: 'Germany',
    currency: 'EUR',
    symbol: '€',
  },
  GH: {
    name: 'Ghana',
    currency: 'GHS',
    symbol: 'GH₵',
  },
  GI: {
    name: 'Gibraltar',
    currency: 'GIP',
    symbol: '£',
  },
  GR: {
    name: 'Greece',
    currency: 'EUR',
    symbol: '€',
  },
  GL: {
    name: 'Greenland',
    currency: 'DKK',
    symbol: 'kr',
  },
  GD: {
    name: 'Grenada',
    currency: 'XCD',
    symbol: '$',
  },
  GU: {
    name: 'Guam',
    currency: 'USD',
    symbol: '$',
  },
  GT: {
    name: 'Guatemala',
    currency: 'GTQ',
    symbol: 'Q',
  },
  GG: {
    name: 'Guernsey',
    currency: 'GBP',
    symbol: '£',
  },
  GN: {
    name: 'Guinea',
    currency: 'GNF',
    symbol: 'FG',
  },
  GW: {
    name: 'Guinea-Bissau',
    currency: 'XOF',
    symbol: 'CFA',
  },
  HT: {
    name: 'Haiti',
    currency: 'HTG',
    symbol: 'G',
  },
  HN: {
    name: 'Honduras',
    currency: 'HNL',
    symbol: 'L',
  },
  HK: {
    name: 'Hong Kong',
    currency: 'HKD',
    symbol: '$',
  },
  HU: {
    name: 'Hungary',
    currency: 'HUF',
    symbol: 'Ft',
  },
  IS: {
    name: 'Iceland',
    currency: 'ISK',
    symbol: 'kr',
  },
  IN: {
    name: 'India',
    currency: 'INR',
    symbol: '₹',
  },
  ID: {
    name: 'Indonesia',
    currency: 'IDR',
    symbol: 'Rp',
  },
  IR: {
    name: 'Iran, Islamic Republic of',
    currency: 'IRR',
    symbol: '﷼',
  },
  IQ: {
    name: 'Iraq',
    currency: 'IQD',
    symbol: 'ع.د',
  },
  IE: {
    name: 'Ireland',
    currency: 'EUR',
    symbol: '€',
  },
  IM: {
    name: 'Isle of Man',
    currency: 'GBP',
    symbol: '£',
  },
  IL: {
    name: 'Israel',
    currency: 'ILS',
    symbol: '₪',
  },
  IT: {
    name: 'Italy',
    currency: 'EUR',
    symbol: '€',
  },
  JM: {
    name: 'Jamaica',
    currency: 'JMD',
    symbol: 'J$',
  },
  JP: {
    name: 'Japan',
    currency: 'JPY',
    symbol: '¥',
  },
  JE: {
    name: 'Jersey',
    currency: 'GBP',
    symbol: '£',
  },
  JO: {
    name: 'Jordan',
    currency: 'JOD',
    symbol: 'JD',
  },
  KZ: {
    name: 'Kazakhstan',
    currency: 'KZT',
    symbol: 'лв',
  },
  KE: {
    name: 'Kenya',
    currency: 'KES',
    symbol: 'KSh',
  },
  KI: {
    name: 'Kiribati',
    currency: 'AUD',
    symbol: '$',
  },
  KP: {
    name: 'North Korea',
    currency: 'KPW',
    symbol: '₩',
  },
  KR: {
    name: 'South Korea',
    currency: 'KRW',
    symbol: '₩',
  },
  KW: {
    name: 'Kuwait',
    currency: 'KWD',
    symbol: 'KD',
  },
  KG: {
    name: 'Kyrgyzstan',
    currency: 'KGS',
    symbol: 'лв',
  },
  LA: {
    name: "Lao People's Democratic Republic",
    currency: 'LAK',
    symbol: '₭',
  },
  LV: {
    name: 'Latvia',
    currency: 'LVL',
    symbol: 'Ls',
  },
  LB: {
    name: 'Lebanon',
    currency: 'LBP',
    symbol: '£',
  },
  LS: {
    name: 'Lesotho',
    currency: 'LSL',
    symbol: 'M',
  },
  LR: {
    name: 'Liberia',
    currency: 'LRD',
    symbol: '$',
  },
  LY: {
    name: 'Libya',
    currency: 'LYD',
    symbol: 'LD',
  },
  LI: {
    name: 'Liechtenstein',
    currency: 'CHF',
    symbol: 'CHF',
  },
  LT: {
    name: 'Lithuania',
    currency: 'LTL',
    symbol: 'Lt',
  },
  LU: {
    name: 'Luxembourg',
    currency: 'EUR',
    symbol: '€',
  },
  MO: {
    name: 'Macao',
    currency: 'MOP',
    symbol: 'MOP$',
  },
  MK: {
    name: 'Republic of Macedonia',
    currency: 'MKD',
    symbol: 'ден',
  },
  MG: {
    name: 'Madagascar',
    currency: 'MGA',
    symbol: 'Ar',
  },
  MW: {
    name: 'Malawi',
    currency: 'MWK',
    symbol: 'MK',
  },
  MY: {
    name: 'Malaysia',
    currency: 'MYR',
    symbol: 'RM',
  },
  MV: {
    name: 'Maldives',
    currency: 'MVR',
    symbol: 'Rf',
  },
  ML: {
    name: 'Mali',
    currency: 'XOF',
    symbol: 'CFA',
  },
  MT: {
    name: 'Malta',
    currency: 'EUR',
    symbol: '€',
  },
  MH: {
    name: 'Marshall Islands',
    currency: 'USD',
    symbol: '$',
  },
  MQ: {
    name: 'Martinique',
    currency: 'EUR',
    symbol: '€',
  },
  MR: {
    name: 'Mauritania',
    currency: 'MRO',
    symbol: 'UM',
  },
  MU: {
    name: 'Mauritius',
    currency: 'MUR',
    symbol: '₨',
  },
  MX: {
    name: 'Mexico',
    currency: 'MXN',
    symbol: '$',
  },
  FM: {
    name: 'Micronesia, Federated States of',
    currency: 'USD',
    symbol: '$',
  },
  MD: {
    name: 'Republic of Moldova',
    currency: 'MDL',
    symbol: 'lei',
  },
  MC: {
    name: 'Monaco',
    currency: 'EUR',
    symbol: '€',
  },
  MN: {
    name: 'Mongolia',
    currency: 'MNT',
    symbol: '₮',
  },
  ME: {
    name: 'Montenegro',
    currency: 'EUR',
    symbol: '€',
  },
  MS: {
    name: 'Montserrat',
    currency: 'XCD',
    symbol: '$',
  },
  MA: {
    name: 'Morocco',
    currency: 'MAD',
    symbol: 'MAD',
  },
  MZ: {
    name: 'Mozambique',
    currency: 'MZN',
    symbol: 'MT',
  },
  MM: {
    name: 'Myanmar',
    currency: 'MMK',
    symbol: 'K',
  },
  NA: {
    name: 'Namibia',
    currency: 'NAD',
    symbol: '$',
  },
  NR: {
    name: 'Nauru',
    currency: 'AUD',
    symbol: '$',
  },
  NP: {
    name: 'Nepal',
    currency: 'NPR',
    symbol: '₨',
  },
  NL: {
    name: 'Netherlands',
    currency: 'EUR',
    symbol: '€',
  },
  NZ: {
    name: 'New Zealand',
    currency: 'NZD',
    symbol: '$',
  },
  NI: {
    name: 'Nicaragua',
    currency: 'NIO',
    symbol: 'C$',
  },
  NE: {
    name: 'Niger',
    currency: 'XOF',
    symbol: 'CFA',
  },
  NG: {
    name: 'Nigeria',
    currency: 'NGN',
    symbol: '₦',
  },
  NU: {
    name: 'Niue',
    currency: 'NZD',
    symbol: '$',
  },
  NF: {
    name: 'Norfolk Island',
    currency: 'AUD',
    symbol: '$',
  },
  MP: {
    name: 'Northern Mariana Islands',
    currency: 'USD',
    symbol: '$',
  },
  NO: {
    name: 'Norway',
    currency: 'NOK',
    symbol: 'kr',
  },
  OM: {
    name: 'Oman',
    currency: 'OMR',
    symbol: '﷼',
  },
  PK: {
    name: 'Pakistan',
    currency: 'PKR',
    symbol: '₨',
  },
  PW: {
    name: 'Palau',
    currency: 'USD',
    symbol: '$',
  },
  PS: {
    name: 'Palestinian Territory',
    currency: 'ILS',
    symbol: '₪',
  },
  PA: {
    name: 'Panama',
    currency: 'PAB',
    symbol: 'B/.',
  },
  PG: {
    name: 'Papua New Guinea',
    currency: 'PGK',
    symbol: 'K',
  },
  PY: {
    name: 'Paraguay',
    currency: 'PYG',
    symbol: 'Gs',
  },
  PE: {
    name: 'Peru',
    currency: 'PEN',
    symbol: 'S/.',
  },
  PH: {
    name: 'Philippines',
    currency: 'PHP',
    symbol: '₱',
  },
  PN: {
    name: 'Pitcairn',
    currency: 'NZD',
    symbol: '$',
  },
  PL: {
    name: 'Poland',
    currency: 'PLN',
    symbol: 'zł',
  },
  PT: {
    name: 'Portugal',
    currency: 'EUR',
    symbol: '€',
  },
  PR: {
    name: 'Puerto Rico',
    currency: 'USD',
    symbol: '$',
  },
  QA: {
    name: 'Qatar',
    currency: 'QAR',
    symbol: '﷼',
  },
  RO: {
    name: 'Romania',
    currency: 'RON',
    symbol: 'lei',
  },
  RU: {
    name: 'Russian',
    currency: 'RUB',
    symbol: '₽',
  },
  RW: {
    name: 'Rwanda',
    currency: 'RWF',
    symbol: 'R₣',
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    currency: 'XCD',
    symbol: '$',
  },
  WS: {
    name: 'Samoa',
    currency: 'WST',
    symbol: 'WS$',
  },
  SM: {
    name: 'San Marino',
    currency: 'EUR',
    symbol: '€',
  },
  ST: {
    name: 'Sao Tome and Principe',
    currency: 'STD',
    symbol: 'Db',
  },
  SA: {
    name: 'Saudi Arabia',
    currency: 'SAR',
    symbol: '﷼',
  },
  SN: {
    name: 'Senegal',
    currency: 'XOF',
    symbol: 'CFA',
  },
  RS: {
    name: 'Serbia',
    currency: 'RSD',
    symbol: 'Дин.',
  },
  SC: {
    name: 'Seychelles',
    currency: 'SCR',
    symbol: '₨',
  },
  SL: {
    name: 'Sierra Leone',
    currency: 'SLL',
    symbol: 'Le',
  },
  SG: {
    name: 'Singapore',
    currency: 'SGD',
    symbol: '$',
  },
  SX: {
    name: 'Sint Maarten',
    currency: 'ANG',
    symbol: 'ƒ',
  },
  SK: {
    name: 'Slovakia',
    currency: 'EUR',
    symbol: '€',
  },
  SI: {
    name: 'Slovenia',
    currency: 'EUR',
    symbol: '€',
  },
  SB: {
    name: 'Solomon Islands',
    currency: 'SBD',
    symbol: '$',
  },
  SO: {
    name: 'Somalia',
    currency: 'SOS',
    symbol: 'S',
  },
  ZA: {
    name: 'South Africa',
    currency: 'ZAR',
    symbol: 'R',
  },
  SS: {
    name: 'South Sudan',
    currency: '',
    symbol: '',
  },
  ES: {
    name: 'Spain',
    currency: 'EUR',
    symbol: '€',
  },
  LK: {
    name: 'Sri Lanka',
    currency: 'LKR',
    symbol: '₨',
  },
  SD: {
    name: 'Sudan',
    currency: 'SDG',
    symbol: 'ج.س.',
  },
  SR: {
    name: 'Suriname',
    currency: 'SRD',
    symbol: '$',
  },
  SZ: {
    name: 'Swaziland',
    currency: 'SZL',
    symbol: 'E',
  },
  SE: {
    name: 'Sweden',
    currency: 'SEK',
    symbol: 'kr',
  },
  CH: {
    name: 'Switzerland',
    currency: 'CHF',
    symbol: 'CHF',
  },
  SY: {
    name: 'Syria',
    currency: 'SYP',
    symbol: '£',
  },
  TW: {
    name: 'Taiwan, Province of China',
    currency: 'TWD',
    symbol: 'NT$',
  },
  TJ: {
    name: 'Tajikistan',
    currency: 'TJS',
    symbol: 'SM',
  },
  TZ: {
    name: 'Tanzania',
    currency: 'TZS',
    symbol: 'TSh',
  },
  TH: {
    name: 'Thailand',
    currency: 'THB',
    symbol: '',
  },
  TG: {
    name: 'Togo',
    currency: 'XOF',
    symbol: 'CFA',
  },
  TK: {
    name: 'Tokelau',
    currency: 'NZD',
    symbol: '$',
  },
  TO: {
    name: 'Tonga',
    currency: 'TOP',
    symbol: 'T$',
  },
  TT: {
    name: 'Trinidad and Tobago',
    currency: 'TTD',
    symbol: 'TT$',
  },
  TN: {
    name: 'Tunisia',
    currency: 'TND',
    symbol: 'د.ت',
  },
  TR: {
    name: 'Turkey',
    currency: 'TRY',
    symbol: '₺',
  },
  TM: {
    name: 'Turkmenistan',
    currency: 'TMT',
    symbol: 'T',
  },
  TC: {
    name: 'Turks and Caicos Islands',
    currency: 'USD',
    symbol: '$',
  },
  TV: {
    name: 'Tuvalu',
    currency: 'AUD',
    symbol: '$',
  },
  UG: {
    name: 'Uganda',
    currency: 'UGX',
    symbol: 'USh',
  },
  UA: {
    name: 'Ukraine',
    currency: 'UAH',
    symbol: '₴',
  },
  AE: {
    name: 'United Arab Emirates',
    currency: 'AED',
    symbol: 'د.إ',
  },
  GB: {
    name: 'United Kingdom',
    currency: 'GBP',
    symbol: '£',
  },
  US: {
    name: 'United States',
    currency: 'USD',
    symbol: '$',
  },
  UY: {
    name: 'Uruguay',
    currency: 'UYU',
    symbol: '$U',
  },
  UZ: {
    name: 'Uzbekistan',
    currency: 'UZS',
    symbol: 'лв',
  },
  VU: {
    name: 'Vanuatu',
    currency: 'VUV',
    symbol: 'VT',
  },
  VE: {
    name: 'Venezuela, Bolivarian Republic of',
    currency: 'VEF',
    symbol: 'Bs',
  },
  VN: {
    name: 'Viet Nam',
    currency: 'VND',
    symbol: '₫',
  },
  VI: {
    name: 'Virgin Islands',
    currency: 'USD',
    symbol: '$',
  },
  YE: {
    name: 'Yemen',
    currency: 'YER',
    symbol: '﷼',
  },
  ZM: {
    name: 'Zambia',
    currency: 'ZMK',
    symbol: '',
  },
  ZW: {
    name: 'Zimbabwe',
    currency: 'ZWL',
    symbol: '',
  },
};
