import { message } from 'antd';
import { Review } from 'apis/APISDK';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_REVIEW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(Review.createReview, payload);

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      yield put({
        type: actions.SET_CREATE_REVIEW,
        payload: data,
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_REVIEW(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data, success } = yield call(Review.listReview, payload);
    if (success) {
      yield put({
        type: actions.SET_LIST_REVIEW,
        payload: {
          reviewList: data.results,
          totalPages: data.totalPages,
          totalResults: data.totalResults,
          pageNo: data.page,
          loading: false,
        },
      });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_REVIEW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const { success, data } = yield call(Review.getReview, {
      id: payload.id,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false },
      });
      payload.next(data);
    } else {
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_REVIEW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Review.updateReview, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      history.push(routes.REVIEW_LIST);
      yield put({
        type: actions.SET_UPDATE_REVIEW,
        payload: data,
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* DELETE_REVIEW(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { formSubmitting: true } });
  try {
    const { success } = yield call(Review.deleteReview, payload);
    if (success) {
      yield put({
        type: actions.SET_DELETE_REVIEW,
        payload: payload.id,
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_REVIEW, CREATE_REVIEW),
    takeEvery(actions.INVOKE_LIST_REVIEW, LIST_REVIEW),
    takeEvery(actions.INVOKE_GET_REVIEW, GET_REVIEW),
    takeEvery(actions.INVOKE_UPDATE_REVIEW, UPDATE_REVIEW),
    takeEvery(actions.INVOKE_DELETE_REVIEW, DELETE_REVIEW),
  ]);
}
