import React from 'react';
import { Select } from 'antd';
// import Flags from 'country-flag-icons/react/3x2';
import countries from './countriesCurrencyData';

const { Option } = Select;
const countryList = Object.entries(countries).map(
  ([code, { name, currency, symbol }]) => ({
    code,
    name,
    currency,
    symbol,
  })
);

const CountryCurrencySelect = ({
  disabled,
  placeholder,
  className,
  style,
  onSelect,
  searchable,
  defaultValue,
  ...props
}) => {
  const filterOption = (input, option) => {
    const { children } = option;
    const text = `${children[0]} (${children[2]})`;
    return text.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Select
      showSearch={searchable}
      filterOption={searchable ? filterOption : false}
      placeholder={placeholder}
      disabled={disabled}
      className={className}
      style={style}
      onSelect={onSelect}
      defaultValue={defaultValue}
      optionLabelProp="label"
      dropdownMatchSelectWidth={270}
      {...props}
    >
      {countryList.map(({ code, name, currency }) => {
        return (
          <Option key={code} value={currency} label={`${currency}`}>
            {name} ({currency})
          </Option>
        );
      })}
    </Select>
  );
};

export default CountryCurrencySelect;
