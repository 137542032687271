import React from 'react';

const Loader = ({
  text = 'Loading...',
  minHeight = '100px',
  maxHeight = '100px',
}) => {
  return (
    <div
      style={{ minHeight, maxHeight }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div className="spinner-border text-primary" role="status" />
      <p className="text-dark mt-2">{text}</p>
    </div>
  );
};

export default Loader;
