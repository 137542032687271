export default {
  SET_STATE: 'domains/SET_STATE',
  SET_DOMAIN_ID_STATUS: 'domains/SET_DOMAIN_ID_STATUS',
  SET_DOMAIN_ID_LOADING: 'domains/SET_DOMAIN_ID_LOADING',
  SET_DELETE_DOMAIN_ID: 'domains/SET_DELETE_DOMAIN_ID',

  INVOKE_CREATE_DOMAIN: 'domains/INVOKE_CREATE_DOMAIN',
  INVOKE_LIST_DOMAIN: 'domains/INVOKE_LIST_DOMAIN',
  INVOKE_GET_DOMAIN: 'domains/INVOKE_GET_DOMAIN',
  INVOKE_UPDATE_DOMAIN: 'domains/INVOKE_UPDATE_DOMAIN',
  INVOKE_DELETE_DOMAIN: 'domains/INVOKE_DELETE_DOMAIN',
  INVOKE_GET_EMAIL_DOMAIN_STATUS: 'domains/INVOKE_GET_EMAIL_DOMAIN_STATUS',
};
