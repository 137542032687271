import actions from './actions';

const initialState = {
  workflowList: [],
  currentWorkflow: [],
  rulesList: [],
  searchList: [],
  loading: false,
  workflowTotalResults: 0,
  workflowTotalPages: 0,
  workflowCurrentPage: 1,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    case actions.SET_ADD_NEW_WORKFLOW:
      return {
        ...state,
        workflowList: [...state.workflowList, action.payload.data],
      };

    default:
      return state;
  }
}
