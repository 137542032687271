import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import store from 'store';
import { history } from 'helpers/history';
import {
  Auth,
  Integration,
  Interview,
  MagicLink,
  Payment,
  Slack,
  Teams,
  User,
} from 'apis/APISDK';
import {
  loadUserAccount,
  loginUser,
  signup,
  logout,
  forgotPassword,
  resendVerifyEmail,
  resetPassword,
  verifyEmail,
  createOrganization,
  signupWithInvite,
  signupCreate,
  getSignupDetails,
} from 'apis/user';
import { handleApiError } from 'redux/error';
import { message } from 'antd';
import { TEAM_INVITE_PAGE_LIMIT } from 'helpers/constants';
import { routes } from 'helpers/routes';
import applicantActions from 'redux/applicants/actions';
import actions from './actions';

function* LOAD_CURRENT_ACCOUNT() {
  yield put({ type: actions.SET_STATE, payload: { authLoading: true } });
  try {
    const token = yield store.get('token');
    if (token) {
      const { success, data } = yield call(loadUserAccount);

      if ((success, data)) {
        yield put({
          type: actions.CURRENT_USER,
          payload: {
            isAuthenticated: true,
            userData: data,
          },
        });
        store.set('user', data);
      } else {
        history.push({ pathname: routes.SIGNOUT });
      }
    } else {
      // to-do send to not auhenticated page
      history.push({ pathname: routes.SIGNUP });
    }
  } catch (e) {
    yield put({
      type: actions.LOGOUT,
    });
    // to-do send to not auhenticated page
    history.push(routes.LOGIN);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { authLoading: false } });
  }
}

function* performLogin(data) {
  const {
    tokens: {
      access: { token: userToken, expires: tokenExpiry } = {},
      refresh: { token: refreshToken, expires: refreshTokenExpiry } = {},
    } = {},
    token: orgToken,
    user,
    organisation,
  } = data;
  message.success('Logged In Successfully');
  if (orgToken) yield store.set('orgToken', orgToken);
  if (user) yield store.set('user', user);
  if (userToken) yield store.set('token', userToken);
  if (tokenExpiry) yield store.set('tokenExpiry', tokenExpiry);
  if (refreshToken) yield store.set('refreshToken', refreshToken);
  if (refreshTokenExpiry)
    yield store.set('refreshTokenExpiry', refreshTokenExpiry);

  if (user) {
    yield put({
      type: actions.CURRENT_USER,
      payload: {
        isAuthenticated: true,
        userData: user,
        organization: organisation,
      },
    });
  }

  if (user?.organisation) {
    yield put({
      type: actions.FETCH_MY_ORGANIZATION,
    });
    history.push(routes.DASHBOARD);
  } else {
    history.push(routes.ORGANIZATION_NEW);
  }
}

function* LOGIN({ payload }) {
  const { email, password } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: { authLoading: true },
  });
  try {
    const {
      success,
      message: resMsg,
      data,
    } = yield call(loginUser, email, password);
    if (success) {
      yield performLogin(data);
    } else {
      message.error(resMsg);
      yield put({
        type: actions.LOGIN_FAILED,
        payload: { error: { isError: true, resMsg } },
      });
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.LOGIN_FAILED,
      payload: {
        error: { isError: true },
      },
    });
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: {
        authLoading: false,
      },
    });
  }
}

function* MAGIC_LOGIN({ payload }) {
  const { email } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: { authLoading: true },
  });
  try {
    const {
      success,
      message: resMsg,
      data,
    } = yield call(MagicLink.createMatchLink, { email });
    if (success) {
      yield store.set('loginToken', data?.tokenId);
      history.push({
        pathname: routes.VERIFY_LOGIN_EMAIL,
      });
    } else {
      message.error(resMsg);
      yield put({
        type: actions.LOGIN_FAILED,
        payload: { error: { isError: true, resMsg } },
      });
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.LOGIN_FAILED,
      payload: {
        error: { isError: true },
      },
    });
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: {
        authLoading: false,
      },
    });
  }
}

function* GET_MAGIC_LINK_STATUS() {
  try {
    const tokenId = yield store.get('loginToken');
    if (!tokenId) return history.push(routes.LOGIN);

    const {
      success,
      data,
      message: resMessage,
    } = yield call(MagicLink.verifyMagicLink, tokenId);

    if (success && data?.isEmailVerified) {
      yield performLogin(data);
      yield store.remove('loginToken');
    } else if (!success) {
      message.error(resMessage);
    }
  } catch (e) {
    console.error('TEst ERROR', e);
  }
}

function* CREATE_ORGANIZATION(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const savedOrgToken = localStorage.getItem('orgToken');
    const token = savedOrgToken ? JSON.parse(savedOrgToken) : null;

    const {
      success,
      message: resMessage,
      data,
    } = yield call(createOrganization, {
      ...payload,
      token,
    });

    if (success) {
      if (data) {
        yield performLogin(data);
      }
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
      // populate my organization
      yield put({
        type: actions.FETCH_MY_ORGANIZATION,
      });
      history.push(routes.DASHBOARD);
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* SIGN_UP({ payload }) {
  const { email, name, invite } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: false, email, name },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(invite ? signupWithInvite : signup, email, name, invite);

    if (success) {
      yield put({ type: actions.SET_STATE, payload: { loading: false } });

      if (data?.tokenId) {
        yield store.set('signupToken', data?.tokenId);
        yield store.set('signupEmail', email);
      }

      if (resMessage === 'Invitation Accepted') {
        yield performLogin(data);
      } else {
        history.push({
          pathname: routes.VERIFY_YOUR_EMAIL,
          state: { email },
        });
      }
    } else {
      if (resMessage === 'Email already has an account') {
        history.push({
          pathname: routes.LOGIN,
          state: { email },
        });
        message.success(
          'You’re already made the list!🎉 Log in or reset your password.'
        );
      } else {
        message.error(resMessage);
      }
      yield put({
        type: actions.SIGN_UP_FAILED,
        payload: { error: { isError: true, message }, loading: false },
      });
    }
  } catch (e) {
    yield put({
      type: actions.SIGN_UP_FAILED,
      payload: {
        error: { isError: true, message: 'Server Error' },
        loading: false,
      },
    });
    console.error(e);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* SIGNUP_CREATE({ payload }) {
  const { name } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: false, name },
  });
  try {
    const tokenId = JSON.parse(localStorage.getItem('signupToken'));
    const {
      success,
      data,
      message: resMessage,
    } = yield call(signupCreate, name, tokenId);

    if (success) {
      yield put({ type: actions.SET_STATE, payload: { loading: false } });

      yield store.remove('signupToken');
      yield store.remove('signupEmail');

      // same process as post login
      yield performLogin(data);
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SIGNUP_CREATE_FAILED,
        payload: { error: { isError: true, message }, loading: false },
      });
    }
  } catch (e) {
    yield put({
      type: actions.SIGNUP_CREATE_FAILED,
      payload: {
        error: { isError: true, message: 'Server Error' },
        loading: false,
      },
    });
    console.error(e);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_SIGNUP_DETAILS() {
  try {
    const tokenId = JSON.parse(localStorage.getItem('signupToken'));
    const {
      success,
      data,
      message: resMessage,
    } = yield call(getSignupDetails, tokenId);

    if (success && data?.isEmailVerified) {
      if (data.organisationExists) history.push(routes.ORGANIZATION_EXISTS);
      else history.push(routes.SIGNUP_CREATE);
    } else if (
      resMessage === 'Token not found' ||
      resMessage === 'Token has expired'
    ) {
      yield put({
        type: actions.SIGN_UP,
        payload: {
          email: JSON.parse(localStorage.getItem('signupEmail')),
        },
      });
    }
  } catch (e) {
    console.error('TEst ERROR', e);
  }
}

function* FORGOT_PASSWORD({ payload }) {
  const { email } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, message: resMsg } = yield call(forgotPassword, email);
    if (success) {
      message.success('Reset password link sent');
      history.push({
        pathname: `${routes.FORGOT_PASSWORD}/sent`,
        state: { ...email },
      });
    } else {
      message.error(resMsg);
      yield put({
        type: actions.FORGOT_PASSWORD_FAILED,
        payload: { error: { isError: true, message } },
      });
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.FORGOT_PASSWORD_FAILED,
      payload: {
        error: { isError: true, message: 'Server Error' },
      },
    });
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* RESET_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, message: resMsg } = yield call(resetPassword, payload);

    if (success) {
      history.push(`${routes.RESET_PASSWORD}/success`);
      message.success('Password successfully updated');
    } else {
      message.error(resMsg);
      history.push(`${routes.RESET_PASSWORD}/invalid-token`);

      yield put({
        type: actions.RESET_PASSWORD_FAILED,
        payload: { error: { isError: true, message } },
      });
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.RESET_PASSWORD_FAILED,
      payload: {
        error: { isError: true, message: 'Server Error' },
      },
    });
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* CHANGE_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, message: resMsg } = yield call(
      Auth.changePassword,
      payload
    );

    if (success) {
      message.success('Password successfully updated');
    } else {
      message.error(resMsg);
    }
  } catch (e) {
    message.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* RESEND_VERIFY_EMAIL() {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const tokenId = JSON.parse(localStorage.getItem('signupToken'));
    const {
      success,
      data,
      message: resMsg,
    } = yield call(resendVerifyEmail, tokenId);

    if (success) {
      if (data?.tokenId) {
        yield store.set('signupToken', data?.tokenId);
      }
      message.success('Verification email sent!');
    } else {
      message.error(resMsg);
      yield put({
        type: actions.RESEND_VERIFY_EMAIL_FAILED,
        payload: { error: { isError: true, message } },
      });
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.RESEND_VERIFY_EMAIL_FAILED,
      payload: {
        error: { isError: true, message: 'Server Error' },
      },
    });
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* VERIFY_EMAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: { isResetting: true },
  });
  try {
    const { success, message: resMsg } = yield call(verifyEmail, payload);

    if (success) {
      message.success('Email successfully verified');
      history.push(`${routes.VERIFY_EMAIL}/success`);
    } else {
      message.error(resMsg);
      if (resMsg === 'Token has expired' || resMsg === 'Token not found') {
        history.push(`${routes.VERIFY_EMAIL}/invalid-token`);
      }

      yield put({
        type: actions.VERIFY_EMAIL_FAILED,
        payload: { error: { isError: true, message } },
      });
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.VERIFY_EMAIL_FAILED,
      payload: {
        error: { isError: true, message: 'Server Error' },
      },
    });
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { isResetting: false },
    });
  }
}

function* INVOKE_CLEAR_PROFILE(payloads) {
  const { payload } = payloads;
  store.set('token', '');
  store.set('orgToken', '');
  store.set('tokenExpiry', '');
  store.set('refreshToken', '');
  store.set('refreshTokenExpiry', '');
  store.set('user', '');
  store.set('reduxState', '');
  yield put({ type: actions.CLEAR_STATE });
  yield put({ type: applicantActions.RESET_STATE });

  if (payload?.redirectRoute) {
    history.push(payload?.redirectRoute);
  } else {
    history.push(routes.LOGIN);
  }
}

function* LOGOUT(payloads) {
  const { payload } = payloads;
  try {
    const refreshToken = yield store.get('refreshToken');
    const { success } = yield call(logout, refreshToken);
    yield put({
      type: actions.INVOKE_CLEAR_PROFILE,
      payload,
    });
    if (success) {
      history.push('/login');
      message.success('Logged out');
    }
  } catch (e) {
    console.error(e);
    history.push('/500');
  }
}

function* FETCH_MY_ORGANIZATION() {
  yield put({
    type: actions.SET_STATE,
    payload: { organizationLoading: true },
  });
  try {
    const { success, data, status } = yield call(User.getMyOrganization);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          organization: {
            ...data?.organisation,
            memberCount: data?.memberCount,
          },
        },
      });
    } else {
      yield handleApiError(status);
    }
  } catch (e) {
    message.error(e);
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { organizationLoading: false },
    });
  }
}

function* UPDATE_ORGANIZATION(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { organizationLoading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      status,
    } = yield call(User.updateOrganization, payload);

    if (success) {
      yield put({
        type: actions.FETCH_MY_ORGANIZATION,
      });
      message.success('Organization details updated');
    } else {
      message.error(resMessage);
      yield handleApiError(status);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { organizationLoading: false },
    });
  }
}

function* UPDATE_ORGANIZATION_SLUG(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { organizationLoading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      status,
    } = yield call(User.updateOrganizationSlug, payload);

    if (success) {
      yield put({
        type: actions.FETCH_MY_ORGANIZATION,
      });
      message.success('Organization slug updated');
    } else {
      message.error(resMessage);
      yield handleApiError(status);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { organizationLoading: false },
    });
  }
}

function* LIST_TEAMS() {
  yield put({
    type: actions.SET_STATE,
    payload: { teamLoading: true },
  });
  try {
    const { success, data } = yield call(Teams.listTeams);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          teams: data.results,
          team: data?.results?.[0] || null,
        },
      });
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: { teamLoading: false },
      });
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { teamLoading: false },
    });
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { teamLoading: false },
    });
  }
}

function* DELETE_TEAM_MEMBER(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { teamLoading: true } });
  try {
    const { success } = yield call(Teams.deleteTeamMember, payload);
    if (success) {
      message.success('Deleted Member Successfully');

      yield put({
        type: actions.LIST_TEAMS,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_USER,
      payload: { formType: payload.formType },
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { teamLoading: false } });
  }
}

function* INVITE_TEAM_MEMBER(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { teamLoading: true },
  });
  try {
    const {
      success,
      status,
      message: resMessage,
    } = yield call(Teams.inviteTeamMember, payload);
    if (success) {
      message.success('Invite sent successfully!');

      yield put({
        type: actions.INVOKE_LIST_INVITES,
        payload: {
          team: payload.team,
          page: 1,
          limit: TEAM_INVITE_PAGE_LIMIT,
        },
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { teamLoading: false },
      });
      yield handleApiError(status);
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { teamLoading: false },
    });
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { teamLoading: false },
    });
  }
}

function* GET_INVITE_DETAILS(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { inviteLoading: true },
  });
  try {
    const { success, data } = yield call(Teams.getInviteDetails, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          inviteDetails: data,
          invitationExpired: false,
        },
      });
    } else if (
      message === 'Invitation not found' ||
      message === 'Token expired'
    ) {
      yield put({
        type: actions.SET_STATE,
        payload: { invitationExpired: true },
      });
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: { invitationExpired: true },
      });
      message.error('Failed to load invitation');
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { inviteLoading: false },
    });
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { inviteLoading: false },
    });
  }
}

function* UPDATE_USER_DETAILS(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { authLoading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      status,
    } = yield call(User.updateUserAccount, payload);

    if (success) {
      yield put({
        type: actions.LOAD_CURRENT_ACCOUNT,
      });
      message.success('User details updated');
    } else {
      message.error(resMessage);
      yield handleApiError(status);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { authLoading: false },
    });
  }
}

function* GET_TEAM(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { teamLoading: true },
  });
  try {
    const { success, data } = yield call(Teams.getTeam, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          teamDetails: data,
        },
      });
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: { teamLoading: false },
      });
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { teamLoading: false },
    });
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { teamLoading: false },
    });
  }
}

function* LIST_INVITES(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { inviteLoading: true },
  });

  try {
    const { success, data } = yield call(Teams.listInvites, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          inviteList: data.results,
          invitesTotalPages: data.totalPages,
          invitesTotalResults: data.totalResults,
        },
      });
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: { inviteLoading: false },
      });
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { inviteLoading: false },
    });
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { inviteLoading: false },
    });
  }
}

function* DELETE_INVITE(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { inviteLoading: true } });
  try {
    const { success } = yield call(Teams.deleteInvite, payload);
    if (success) {
      message.success('Deleted Invite Successfully');
      const { team, invitesPageNo } = yield select((state) => state.user);

      yield put({
        type: actions.INVOKE_LIST_INVITES,
        payload: {
          team: team?.id,
          page: invitesPageNo,
          limit: TEAM_INVITE_PAGE_LIMIT,
        },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_USER,
      payload: { formType: payload.formType },
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { inviteLoading: false } });
  }
}

function* RESEND_INVITE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { inviteLoading: true },
  });
  try {
    const { success } = yield call(Teams.resendInvite, payload);
    if (success) {
      message.success('Sent invite successfully');
    } else {
      message.error('Failed to send invite');
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { inviteLoading: false },
    });
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { inviteLoading: false },
    });
  }
}

function* CREATE_CAL_INTEGRATION(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { integrationLoading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      status,
    } = yield call(Integration.addCalIntegration, payload);

    if (success) {
      yield put({
        type: actions.CHECK_CAL_INTEGRATION,
      });
      message.success('Organization details updated');
    } else {
      message.error(resMessage);
      yield handleApiError(status);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { integrationLoading: false },
    });
  }
}

function* CHECK_CAL_INTEGRATION() {
  yield put({
    type: actions.SET_STATE,
    payload: { integrationLoading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
      status,
    } = yield call(Integration.checkCalIntegration);

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          calIntegration: data?.cal,
          calWebhook: data?.calWebhook,
        },
      });
    } else {
      message.error(resMessage);
      yield handleApiError(status);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { integrationLoading: false },
    });
  }
}

function* FETCH_CAL_EVENTS() {
  yield put({
    type: actions.SET_STATE,
    payload: { integrationLoading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
      status,
    } = yield call(Integration.getCalEvents);

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { calEvents: data },
      });
    } else {
      message.error(resMessage);
      yield handleApiError(status);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { integrationLoading: false },
    });
  }
}

function* CREATE_CAL_EVENT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: { integrationLoading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      status,
    } = yield call(Integration.createCalEvent, payload);

    if (success) {
      message.success('Event type created');
      yield put({
        type: actions.FETCH_CAL_EVENTS,
      });
    } else {
      message.error(resMessage);
      yield handleApiError(status);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { integrationLoading: false },
    });
  }
}

function* ADD_CAL_WEBHOOK() {
  yield put({
    type: actions.SET_STATE,
    payload: { integrationLoading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      status,
    } = yield call(Integration.addCalWebhook);

    if (success) {
      message.success('Webhook created successfully');
      yield put({
        type: actions.CHECK_CAL_INTEGRATION,
      });
    } else {
      message.error(resMessage);
      yield handleApiError(status);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { integrationLoading: false },
    });
  }
}

function* SYNC_CAL_BOOKINGS() {
  yield put({
    type: actions.SET_STATE,
    payload: { integrationLoading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      status,
    } = yield call(Interview.syncCalBookings);

    if (success) {
      message.success('Synced all Cal.com bookings');
    } else {
      message.error(resMessage);
      yield handleApiError(status);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { integrationLoading: false },
    });
  }
}

function* FETCH_PLANS() {
  yield put({
    type: actions.SET_STATE,
    payload: { plansLoading: true },
  });
  try {
    const { success, data, status } = yield call(Payment.getPlanDetails);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          plans: data,
        },
      });
    } else {
      yield handleApiError(status);
    }
  } catch (e) {
    message.error(e);
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { plansLoading: false },
    });
  }
}

function* GET_SLACK_OAUTH_URL() {
  yield put({
    type: actions.SET_STATE,
    payload: { integrationLoading: true },
  });
  try {
    const { success, data } = yield call(Slack.getSlackInstallURL);

    if (success) {
      window.location.href = data;
    } else message.error('Failed to get slack installation URL');
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { integrationLoading: false },
    });
  }
}

function* SYNC_SLACK_USERS() {
  yield put({
    type: actions.SET_STATE,
    payload: { slackSyncing: true },
  });
  try {
    const { success } = yield call(Slack.syncSlack);

    if (success) {
      message.success('Slack users synced successfully');
    } else message.error('Failed to sync slack users');
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { slackSyncing: false },
    });
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.MAGIC_LOGIN, MAGIC_LOGIN),
    takeEvery(actions.SIGN_UP, SIGN_UP),
    takeEvery(actions.SIGNUP_CREATE, SIGNUP_CREATE),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.GET_SIGNUP_DETAILS, GET_SIGNUP_DETAILS),
    takeEvery(actions.RESEND_VERIFY_EMAIL, RESEND_VERIFY_EMAIL),
    takeEvery(actions.VERIFY_EMAIL, VERIFY_EMAIL),
    takeEvery(actions.CREATE_ORGANIZATION, CREATE_ORGANIZATION),
    takeEvery(actions.FETCH_MY_ORGANIZATION, FETCH_MY_ORGANIZATION),
    takeEvery(actions.LIST_TEAMS, LIST_TEAMS),
    takeEvery(actions.INVOKE_GET_TEAM, GET_TEAM),
    takeEvery(actions.INVOKE_DELETE_TEAM_MEMBER, DELETE_TEAM_MEMBER),
    takeEvery(actions.INVITE_TEAM_MEMBER, INVITE_TEAM_MEMBER),
    takeEvery(actions.GET_INVITE_DETAILS, GET_INVITE_DETAILS),
    takeEvery(actions.UPDATE_ORGANIZATION, UPDATE_ORGANIZATION),
    takeEvery(actions.INVOKE_CLEAR_PROFILE, INVOKE_CLEAR_PROFILE),
    takeEvery(actions.INVOKE_CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeEvery(actions.UPDATE_USER_DETAILS, UPDATE_USER_DETAILS),
    takeEvery(actions.INVOKE_LIST_INVITES, LIST_INVITES),
    takeEvery(actions.INVOKE_DELETE_INVITE, DELETE_INVITE),
    takeEvery(actions.INVOKE_RESEND_INVITE, RESEND_INVITE),
    takeEvery(actions.CREATE_CAL_INTEGRATION, CREATE_CAL_INTEGRATION),
    takeEvery(actions.CHECK_CAL_INTEGRATION, CHECK_CAL_INTEGRATION),
    takeEvery(actions.FETCH_CAL_EVENTS, FETCH_CAL_EVENTS),
    takeEvery(actions.CREATE_CAL_EVENT, CREATE_CAL_EVENT),
    takeEvery(actions.ADD_CAL_WEBHOOK, ADD_CAL_WEBHOOK),
    takeEvery(actions.SYNC_CAL_BOOKINGS, SYNC_CAL_BOOKINGS),
    takeEvery(actions.UPDATE_ORGANIZATION_SLUG, UPDATE_ORGANIZATION_SLUG),
    takeEvery(actions.FETCH_PLANS, FETCH_PLANS),
    takeEvery(actions.GET_MAGIC_LINK_STATUS, GET_MAGIC_LINK_STATUS),
    takeEvery(actions.GET_SLACK_INSTALLATION, GET_SLACK_OAUTH_URL),
    takeEvery(actions.SYNC_SLACK, SYNC_SLACK_USERS),
  ]);
}
