export default {
  SET_STATE: 'jobs/SET_STATE',

  INVOKE_CREATE_JOB: 'jobs/INVOKE_CREATE_JOB',
  INVOKE_DUPLICATE_JOB: 'jobs/INVOKE_DUPLICATE_JOB',
  INVOKE_LIST_JOB: 'jobs/INVOKE_LIST_JOB',
  INVOKE_GET_JOB: 'jobs/INVOKE_GET_JOB',
  INVOKE_UPDATE_JOB: 'jobs/INVOKE_UPDATE_JOB',
  INVOKE_DELETE_JOB: 'jobs/INVOKE_DELETE_JOB',

  INVOKE_ADD_JOB_TEAM_MEMBER: 'jobs/INVOKE_ADD_JOB_TEAM_MEMBER',
  INVOKE_DELETE_JOB_TEAM_MEMBER: 'jobs/INVOKE_DELETE_JOB_TEAM_MEMBER',
};
