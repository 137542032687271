import React from 'react';
import style from './style.module.scss';

const PublicLayout = ({ children }) => {
  return (
    <div>
      <div className={style.page}>
        <div className={style.main}>{children}</div>
      </div>
    </div>
  );
};

export default PublicLayout;
