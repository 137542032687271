import React from 'react';
import { withRouter } from 'react-router-dom';
import PublicLayout from './PublicLayout';
import JobLayout from './JobLayout';
import AuthLayout from './AuthLayout';
import JobsLayout from './JobsLayout';
import AccountLayout from './AccountLayout';
import OrganizationLayout from './OrganizationLayout';
import NotificationLayout from './NotificationLayout';
import ReviewLayout from './ReviewLayout';
import PortalLayout from './PortalLayout';
import ApplicantReviewsLayout from './ApplicantReviewsLayout';

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  job: JobLayout,
  dashboard: JobsLayout,
  account: AccountLayout,
  organization: OrganizationLayout,
  notification: NotificationLayout,
  reviews: ReviewLayout,
  portals: PortalLayout,
  applicantReviews: ApplicantReviewsLayout,
};

const IndexLayout = ({ children, history }) => {
  const pathname = history?.location?.pathname;
  // Layout Rendering

  const getLayout = () => {
    if (/^\/account/.test(pathname)) {
      return 'account';
    }
    if (/^\/reviews/.test(pathname)) {
      return 'reviews';
    }
    if (/^\/applicant-reviews/.test(pathname)) {
      return 'applicantReviews';
    }
    if (/^\/organization/.test(pathname)) {
      return 'organization';
    }
    if (/^\/notification/.test(pathname)) {
      return 'notification';
    }
    if (/^\/dashboard/.test(pathname)) {
      return 'dashboard';
    }
    if (
      /^\/jobs/.test(pathname) ||
      /^\/talent-community/.test(pathname) ||
      /^\/all-/.test(pathname) ||
      /^\/communications/.test(pathname)
    ) {
      return 'job';
    }
    if (/^\/portals/.test(pathname)) {
      return 'portals';
    }

    if (
      /^\/login/.test(pathname) ||
      /^\/forgot-password/.test(pathname) ||
      /^\/signup/.test(pathname) ||
      /^\/verify-email/.test(pathname) ||
      /^\/verify-your-email/.test(pathname) ||
      /^\/reset-password/.test(pathname)
    ) {
      return 'auth';
    }
    // if (
    //   /^\/login(?=\/|$)/i.test(pathname) ||
    //   /^\/signup(?=\/|$)/i.test(pathname) ||
    //   /^\/onboarding(?=\/|$)/i.test(pathname) ||
    //   /^\/verify-otp(?=\/|$)/i.test(pathname) ||
    //   /^\/register(?=\/|$)/i.test(pathname)
    // ) {
    //   return 'login';
    // }
    return 'public';
  };

  const Container = Layouts[getLayout()];

  return <Container>{children}</Container>;
};

export default withRouter(IndexLayout);
