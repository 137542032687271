export default {
  SET_STATE: 'talentCommunity/SET_STATE',
  SET_UPDATE_TALENT_APPLICANT: 'talentCommunity/SET_UPDATE_TALENT_APPLICANT',
  SET_DELETE_TALENT_APPLICANT: 'talentCommunity/SET_DELETE_TALENT_APPLICANT',
  SET_ADD_TALENT_APPLICANT: 'talentCommunity/SET_ADD_TALENT_APPLICANT',

  INVOKE_LIST_TALENT_COMMUNITY: 'talentCommunity/INVOKE_LIST_TALENT_COMMUNITY',
  INVOKE_GET_TALENT_APPLICANT: 'talentCommunity/INVOKE_GET_TALENT_APPLICANT',
  INVOKE_CREATE_TALENT_APPLICANT:
    'talentCommunity/INVOKE_CREATE_TALENT_APPLICANT',
  INVOKE_UPDATE_TALENT_APPLICANT:
    'talentCommunity/INVOKE_UPDATE_TALENT_APPLICANT',
  INVOKE_DELETE_TALENT_APPLICANT:
    'talentCommunity/INVOKE_DELETE_TALENT_APPLICANT',
  SET_ADD_TALENT_APPLICANT_LIST:
    'talentCommunity/SET_ADD_TALENT_APPLICANT_LIST',
};
