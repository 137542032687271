import React from 'react';
import { Button, Card, Result } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from 'redux/user/actions';
import { routes } from 'helpers/routes';
import styles from './styles.module.css';

const LogoutRequired = ({ fallbackRoute, logout }) => {
  const history = useHistory();
  const location = useLocation();

  const handleLogout = () => {
    logout({
      redirectRoute: location.pathname,
    });
  };

  const handleGoHome = () => {
    history.push(routes.DASHBOARD);
  };

  const renderCustomMessage = () => {
    switch (fallbackRoute) {
      case routes.SIGNUP_WITH_INVITE:
        return 'You need to logout of the current account to register with an invite.';
      case routes.VERIFY_EMAIL:
        return 'You need to logout of the current account to verify your email.';
      default:
        return 'Please logout of the current account to access this link.';
    }
  };

  return (
    <div className={styles.container}>
      <Card
        className="border"
        style={{
          width: 500,
        }}
      >
        <Result
          subTitle={
            <div className={styles.message}>{renderCustomMessage()}</div>
          }
          extra={
            <div className="flex justify-space-between">
              <Button type="primary" className="me-3" onClick={handleLogout}>
                Logout
              </Button>
              <Button type="default" onClick={handleGoHome}>
                Go Home
              </Button>
            </div>
          }
        />
      </Card>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: (payload) =>
      dispatch({
        type: actions.LOGOUT,
        payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutRequired);
