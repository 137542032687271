import actions from './actions';

const initialState = {
  recordingsList: [],
  currentRecording: null,
  loading: false,
  recordingTotalResults: 0,
  recordingTotalPages: 0,
  allRecordingList: [],
  allRecordingTotalResults: 0,
  allRecordingTotalPages: 0,
  allRecordingCurrentPage: 1,
  allRecordingLoading: false,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    case actions.SET_ADD_ALL_RECORDING_LIST: {
      if (
        state.allRecordingCurrentPage === action.payload.allRecordingCurrentPage
      )
        return { ...state };
      const { allRecordingList } = state;
      allRecordingList.push(...action.payload.allRecordingList);

      return { ...state, ...action.payload, allRecordingList };
    }

    default:
      return state;
  }
}
