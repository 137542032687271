import { message } from 'antd';
import { Comment } from 'apis/APISDK';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_COMMENT(payloads) {
  const { payload, cb = null } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      Comment.createComment,
      payload
    );

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      yield put({
        type: actions.INVOKE_LIST_COMMENT,
        payload: {
          id: payload.data.candidate,
        },
      });
      if (cb) cb();
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_COMMENT(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(Comment.listComment, payload);
    yield put({
      type: actions.SET_STATE,
      payload: { commentList: data.results, loading: false },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_COMMENT(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const { success, data } = yield call(Comment.getComment, {
      id: payload.id,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false },
      });
      payload.next(data);
    } else {
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_COMMENT(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Comment.updateComment, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      history.push(routes.COMMENT_LIST);
      yield put({
        type: actions.INVOKE_LIST_COMMENT,
        payload: {
          id: payload.candidate,
        },
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* DELETE_COMMENT(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(Comment.deleteComment, payload);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
    yield put({
      type: actions.INVOKE_LIST_COMMENT,
      payload: {
        id: payload.candidate,
      },
    });
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
    yield put({
      type: actions.INVOKE_LIST_COMMENT,
      payload: {
        payload: {
          id: payload.candidate,
        },
      },
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_COMMENT, CREATE_COMMENT),
    takeEvery(actions.INVOKE_LIST_COMMENT, LIST_COMMENT),
    takeEvery(actions.INVOKE_GET_COMMENT, GET_COMMENT),
    takeEvery(actions.INVOKE_UPDATE_COMMENT, UPDATE_COMMENT),
    takeEvery(actions.INVOKE_DELETE_COMMENT, DELETE_COMMENT),
  ]);
}
