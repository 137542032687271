import React, { Component } from 'react';
import { Result, Button } from 'antd';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Result
          status="error"
          title="Something went wrong"
          subTitle="Sorry, something went wrong. Please try again later."
          extra={
            <Button
              type="primary"
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </Button>
          }
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
