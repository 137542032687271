export default {
  SET_STATE: 'reviews/SET_STATE',
  SET_LIST_REVIEW: 'reviews/SET_LIST_REVIEW',
  SET_CREATE_REVIEW: 'reviews/SET_CREATE_REVIEW',
  SET_UPDATE_REVIEW: 'reviews/SET_UPDATE_REVIEW',
  SET_DELETE_REVIEW: 'reviews/SET_DELETE_REVIEW',

  INVOKE_CREATE_REVIEW: 'reviews/INVOKE_CREATE_REVIEW',
  INVOKE_LIST_REVIEW: 'reviews/INVOKE_LIST_REVIEW',
  INVOKE_GET_REVIEW: 'reviews/INVOKE_GET_REVIEW',
  INVOKE_UPDATE_REVIEW: 'reviews/INVOKE_UPDATE_REVIEW',
  INVOKE_DELETE_REVIEW: 'reviews/INVOKE_DELETE_REVIEW',
};
