import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const queryParams = {};

    searchParams.forEach((value, key) => {
      queryParams[key] = decodeURIComponent(value.replace(' ', '+'));
    });

    return queryParams;
  }, [search]);
}
