import React from 'react';
import { DEFAULT_FIELD_TYPES, FIELD_CATEGORIES } from 'helpers/constants';
import CurrencyFilter from './CurrencyFilter';
import TextFilter from './TextFilter';
import MultiChoiceFilter from './MultiChoiceFilter';
import LocationFilter from './LocationFilter';

export const generateFilterFields = (field, filter, setFilter = () => {}) => {
  if (
    field.category === FIELD_CATEGORIES.personalInfo ||
    field.category === FIELD_CATEGORIES.socialLinks ||
    field.category === FIELD_CATEGORIES.files
  )
    return null;

  if (field.fieldType === DEFAULT_FIELD_TYPES.url) return null;

  if (field.fieldType === DEFAULT_FIELD_TYPES.currency) {
    return (
      <CurrencyFilter
        key={field.name}
        title={field.label}
        minValue={
          filter[field.name]?.value && filter[field.name]?.value.split('-')[0]
        }
        maxValue={
          filter[field.name]?.value && filter[field.name]?.value.split('-')[1]
        }
        currencyCodeValue={filter[field.name]?.code}
        onCurrencySelect={(e) => {
          setFilter((prev) => {
            return {
              ...prev,
              [field.name]: {
                code: e,
                value: prev[field.name]?.value || '',
              },
            };
          });
        }}
        onMinFilterChange={(e) =>
          setFilter((prev) => {
            return {
              ...prev,
              [field.name]: {
                code: prev[field.name]?.code || '',
                value: `${e}-${prev[field.name]?.value.split('-')[1]}`,
              },
            };
          })
        }
        onMaxFilterChange={(e) =>
          setFilter((prev) => {
            return {
              ...prev,
              [field.name]: {
                code: prev[field.name]?.code || '',
                value: `${prev[field.name]?.value.split('-')[0]}-${e}`,
              },
            };
          })
        }
      />
    );
  }
  if (
    field.fieldType === DEFAULT_FIELD_TYPES.text ||
    field.fieldType === DEFAULT_FIELD_TYPES.number
  )
    return (
      <TextFilter
        key={field.name}
        title={field.label}
        type={field.fieldType}
        value={filter[field.name]}
        onChange={(e) =>
          setFilter((prev) => {
            return {
              ...prev,
              [field.name]: e,
            };
          })
        }
      />
    );

  if (field.fieldType === DEFAULT_FIELD_TYPES.multipleChoice)
    return (
      <MultiChoiceFilter
        key={field.name}
        title={field.label}
        value={filter[field.name]}
        onChange={(e) =>
          setFilter((prev) => {
            return {
              ...prev,
              [field.name]: e,
            };
          })
        }
        options={field.options}
      />
    );

  if (field.fieldType === DEFAULT_FIELD_TYPES.yesNo)
    return (
      <MultiChoiceFilter
        key={field.name}
        title={field.label}
        value={filter[field.name]}
        onChange={(e) =>
          setFilter((prev) => {
            return {
              ...prev,
              [field.name]: e,
            };
          })
        }
        options={['Yes', 'No']}
        hasLowerCaseValue
      />
    );

  if (field.fieldType === DEFAULT_FIELD_TYPES.location)
    return (
      <LocationFilter
        key={field.name}
        title={field.label}
        value={filter[field.name]}
        onChange={(e) =>
          setFilter((prev) => {
            return {
              ...prev,
              [field.name]: e,
            };
          })
        }
      />
    );

  return null;
};

export const generateDefaultFilterValues = (formFields) => {
  const filter = {};

  formFields.forEach((field) => {
    if (
      field.category === FIELD_CATEGORIES.personalInfo ||
      field.category === FIELD_CATEGORIES.socialLinks ||
      field.category === FIELD_CATEGORIES.files
    )
      return;
    if (field.fieldType === DEFAULT_FIELD_TYPES.currency) {
      filter[field.name] = null;
    } else if (
      field.fieldType === DEFAULT_FIELD_TYPES.text ||
      field.fieldType === DEFAULT_FIELD_TYPES.number
    )
      filter[field.name] = null;
    else if (field.fieldType === DEFAULT_FIELD_TYPES.multipleChoice)
      filter[field.name] = null;
    else if (field.fieldType === DEFAULT_FIELD_TYPES.yesNo)
      filter[field.name] = null;
    else if (field.fieldType === DEFAULT_FIELD_TYPES.location)
      filter[field.name] = null;
  });

  return filter;
};
