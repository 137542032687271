import actions from './actions';

const initialState = {
  candidatesList: [],
  loading: false,
  formLoading: false,
  formSubmitting: false,
  totalPages: 1,
  page: 1,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SET_ADD_CANDIDATES:
      return {
        ...state,
        candidatesList: [
          ...state.candidatesList,
          ...action.payload.candidatesList,
        ],
      };

    default:
      return state;
  }
}
