import { message } from 'antd';
import { Pages } from 'apis/APISDK';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* LIST_PAGES(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Pages.getPages, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          pages: data?.results,
          pagesTotalResults: data?.totalResults,
          pagesTotalPages: data?.totalPages,
          loading: false,
        },
      });
    } else {
      message.error(resMessage);
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_PAGE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data } = yield call(Pages.getPage, payload);
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { currentPage: data, loading: false },
      });
    } else {
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* UPDATE_PAGE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data } = yield call(Pages.updatePage, payload);
    if (success && data) {
      message.success('Page updated');
      yield put({ type: actions.INVOKE_LIST_PAGES });
    } else message.error('Page update failed');
  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* DELETE_PAGE(payloads) {
  const { payload } = payloads;

  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { success } = yield call(Pages.deletePage, payload);
    if (success) {
      message.success('Page deleted');
      yield put({ type: actions.INVOKE_LIST_PAGES });
    } else message.error('Page deletion failed');
  } catch (error) {
    console.error(error);
  }
}

function* CREATE_PAGE(payloads) {
  const { payload } = payloads;

  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success } = yield call(Pages.createPage, payload);
    if (success) {
      message.success('Page created');
      yield put({ type: actions.INVOKE_LIST_PAGES });
    } else message.error('Page creation failed');
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_LIST_PAGES, LIST_PAGES),
    takeEvery(actions.INVOKE_GET_PAGE, GET_PAGE),
    takeEvery(actions.INVOKE_UPDATE_PAGE, UPDATE_PAGE),
    takeEvery(actions.INVOKE_DELETE_PAGE, DELETE_PAGE),
    takeEvery(actions.INVOKE_CREATE_PAGE, CREATE_PAGE),
  ]);
}
