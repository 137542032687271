import { message } from 'antd';
import { Stage } from 'apis/APISDK';
import workflowActions from 'redux/workflows/actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_STAGE(payloads) {
  const { payload, next } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(Stage.addStage, payload.data);

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
      if (data?.id) {
        yield put({
          type: workflowActions.INVOKE_UPDATE_WORKFLOW_STAGES,
          payload: {
            stages: [data.id],
            id: payload.workflowId,
          },
        });
        if (next) next();
      }
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* LIST_STAGES(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(Stage.listStages, payload);
    yield put({
      type: actions.SET_STATE,
      payload: { stageList: data.results, loading: false },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_STAGE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const { success, data } = yield call(Stage.getStageById, {
      id: payload.id,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false, currentStage: data },
      });
      if (payload?.next) payload.next(data);
    } else {
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_STAGE(payloads) {
  const { payload, next } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Stage.updateStage, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
      if (payload?.workflowId) {
        yield put({
          type: workflowActions.INVOKE_GET_WORKFLOW,
          payload: {
            id: payload?.workflowId,
          },
        });
      }

      if (next) next();
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* DELETE_STAGE(payloads) {
  const { payload, next } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { success } = yield call(Stage.deleteStage, payload);
    if (success && payload?.workflow) {
      yield put({
        type: workflowActions.INVOKE_GET_WORKFLOW,
        payload: {
          id: payload?.workflow,
        },
      });
    }

    if (next) next();
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_STAGES,
      payload: {
        id: payload.id,
      },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_STAGE, CREATE_STAGE),
    takeEvery(actions.INVOKE_LIST_STAGES, LIST_STAGES),
    takeEvery(actions.INVOKE_GET_STAGE, GET_STAGE),
    takeEvery(actions.INVOKE_UPDATE_STAGE, UPDATE_STAGE),
    takeEvery(actions.INVOKE_DELETE_STAGE, DELETE_STAGE),
  ]);
}
