/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import store from 'store';
import LogoutRequired from 'pages/logoutRequired';
import { LOGOUT_REQUIRED_ROUTES, routes } from './routes';

export const OrgPrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      store.get('orgToken') || store.get('token') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      store.get('token') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

export const AuthRoute = ({ component: Component, ...rest }) => {
  const { path } = rest;
  const isLogoutRequiredRoute = LOGOUT_REQUIRED_ROUTES.includes(path);
  const isAuthenticated = store.get('token');

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && !isLogoutRequiredRoute) {
          return (
            <Redirect
              to={{
                pathname: routes.DASHBOARD,
                state: { from: props.location },
              }}
            />
          );
        }

        if (isLogoutRequiredRoute && isAuthenticated) {
          return <LogoutRequired fallbackRoute={path} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};
