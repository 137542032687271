import { APPLICANT_PAGE_LIMIT } from 'helpers/constants';
import actions from './actions';

const initialState = {
  loading: false,
  formSubmitting: false,
  currentTalentApplicant: null,
  talentCommunityList: [],
  applicantsTotalPages: 0,
  applicantsTotalResults: 0,
  applicantLimit: APPLICANT_PAGE_LIMIT,
  applicantsCurrentPage: 1,
};

// eslint-disable-next-line default-param-last
export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    case actions.SET_ADD_TALENT_APPLICANT: {
      const addedData = action.payload.data;
      const talentCommunityList = [addedData, ...state.talentCommunityList];

      return { ...state, talentCommunityList };
    }

    case actions.SET_UPDATE_TALENT_APPLICANT: {
      const idToUpdate = action.payload.id;
      const talentCommunityList = state.talentCommunityList.map((obj) =>
        obj.id === idToUpdate ? { ...obj, ...action.payload.data } : obj
      );

      let { currentTalentApplicant } = state;
      currentTalentApplicant = {
        ...currentTalentApplicant,
        ...action.payload.data,
      };

      return { ...state, talentCommunityList, currentTalentApplicant };
    }

    case actions.SET_DELETE_TALENT_APPLICANT: {
      const idToDelete = action.payload.id;
      const updatedTalentCommunityList = state.talentCommunityList.filter(
        (applicant) => applicant.id !== idToDelete
      );

      return {
        ...state,
        talentCommunityList: updatedTalentCommunityList,
      };
    }

    case actions.SET_ADD_TALENT_APPLICANT_LIST: {
      if (state.applicantsCurrentPage === action.payload.applicantsCurrentPage)
        return { ...state };
      const { talentCommunityList } = state;
      talentCommunityList.push(...action.payload.talentCommunityList);

      return { ...state, ...action.payload, talentCommunityList };
    }
    default:
      return state;
  }
}
