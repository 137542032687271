import actions from './actions';

const initialState = {
  categoryList: [],
  loading: false,
  formLoading: false,
  formSubmitting: false,
  totalPages: 1,
  totalResults: 0,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
