import { JOB_STATUS_TYPE } from 'helpers/constants';
import actions from './actions';

const initialState = {
  jobList: [],
  jobListCandidateCount: null,
  current: null,
  currentPage: 1,
  jobTotalPages: 0,
  jobTotalResults: 0,
  currentCandidateCount: null,
  loading: false,
  formLoading: false,
  formSubmitting: false,
  lastCreatedJob: null,
  activeJobsStatus: JOB_STATUS_TYPE.active,
  jobListStages: [],
  jobsStages: [],
  archiveStage: null,
  filter: null,
  shortLinkLoading: false,
  jobShortLink: null,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
