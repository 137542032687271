import actions from './actions';

const initialState = {
  portalJobList: [],
  current: null,
  jobTotalPages: 0,
  jobTotalResults: 0,
  loading: false,
  formLoading: false,
  formSubmitting: false,
  organizationLoading: false,
  organization: null,
  applicationForm: null,
  applicationFormLoading: null,
  jobApplicationMessage: null,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
