import { message } from 'antd';
import { Domain } from 'apis/APISDK';
import { history } from 'helpers/history';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_DOMAIN(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { formSubmitting: true } });
  try {
    const { success, message: resMessage } = yield call(
      Domain.createDomain,
      payload
    );
    if (success) {
      yield put({ type: actions.INVOKE_LIST_DOMAIN });
      if (payloads?.redirect?.valid) history.push(payloads.redirect.link);
      message.success(resMessage);
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    message.error(error.toString());
  } finally {
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_DOMAIN() {
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(Domain.listDomains);
    yield put({
      type: actions.SET_STATE,
      payload: { list: data.results, loading: false },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_DOMAIN(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const res = yield call(Domain.getDomain, { id: payload.id });
    if (res) {
      yield put({
        type: actions.SET_STATE,
        payload: { current: res, loading: false },
      });
      payload.next(res);
    } else {
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* UPDATE_DOMAIN(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_DOMAIN_ID_LOADING,
    payload: { loading: true, id: payload.id },
  });
  try {
    const {
      success,
      data,
      message: resMsg,
    } = yield call(Domain.updateDomain, payload);

    if (success) {
      yield put({
        type: actions.SET_DOMAIN_ID_STATUS,
        payload: { id: payload.id, domainStatus: data?.domainStatus },
      });
    } else {
      message.error(resMsg);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_DOMAIN_ID_LOADING,
      payload: { loading: false, id: payload.id },
    });
  }
}

function* DELETE_DOMAIN(payloads) {
  const { payload } = payloads;
  try {
    const { success, message: resMsg } = yield call(
      Domain.deleteDomain,
      payload
    );
    if (success) {
      yield put({
        type: actions.SET_DELETE_DOMAIN_ID,
        payload: { id: payload.id },
      });
      message.success(resMsg);
    } else {
      message.error(resMsg);
    }
  } catch (error) {
    console.error(error);
  }
}

function* GET_EMAIL_DOMAIN_STATUS() {
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(Domain.checkEmailDomainStatus);
    yield put({
      type: actions.SET_STATE,
      payload: { emailDomainStatus: data.status, loading: false },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_DOMAIN, CREATE_DOMAIN),
    takeEvery(actions.INVOKE_LIST_DOMAIN, LIST_DOMAIN),
    takeEvery(actions.INVOKE_GET_DOMAIN, GET_DOMAIN),
    takeEvery(actions.INVOKE_UPDATE_DOMAIN, UPDATE_DOMAIN),
    takeEvery(actions.INVOKE_DELETE_DOMAIN, DELETE_DOMAIN),
    takeEvery(actions.INVOKE_GET_EMAIL_DOMAIN_STATUS, GET_EMAIL_DOMAIN_STATUS),
  ]);
}
