import { message } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Portal } from 'apis/APISDK';
import {
  JOB_ALREADY_SUBMITTED_MESSAGE,
  JOB_APPLICATION_SUCCESS_MESSAGE,
} from 'helpers/constants';
import { handleApiError } from 'redux/error';
import actions from './actions';

function* LIST_JOB(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data, success, status } = yield call(Portal.listOrgJobs, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          portalJobList: data.results,
          jobTotalResults: data.totalResults,
          jobTotalPages: data.totalPages,
          loading: false,
        },
      });
    } else {
      yield handleApiError(status);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_JOB(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data, status } = yield call(
      Portal.getOrgJobDetails,
      payload
    );
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: data,
          loading: false,
        },
      });
    } else {
      yield handleApiError(status);
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
      message.error('Failed to fetch job details, please try again.');
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_PUBLIC_ORGANIZATION(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { organizationLoading: true },
  });
  try {
    const { success, data, status } = yield call(
      Portal.getOrganizationDetails,
      payload
    );
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          organization: data,
        },
      });
    } else {
      yield handleApiError(status);
    }
  } catch (e) {
    message.error(e);

    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { organizationLoading: false },
    });
  }
}

function* GET_PORTAL_JOB_FORM(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { applicationFormLoading: true },
  });
  try {
    const { success, data, status } = yield call(
      Portal.getApplicationFormDetails,
      payload
    );
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          applicationForm: data,
        },
      });
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: { applicationFormLoading: false },
      });
      yield handleApiError(status);
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { applicationFormLoading: false },
    });
    console.error(e);
  }
}

function* APPLY_PORTAL_JOB(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      success,
      status,
      message: resMessage,
    } = yield call(Portal.applyPortalJob, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          formSubmitting: false,
          jobApplicationMessage: JOB_APPLICATION_SUCCESS_MESSAGE,
        },
      });
    } else {
      let errMessage = 'Submission Failed. Please try again later.';
      if (resMessage === JOB_ALREADY_SUBMITTED_MESSAGE) {
        errMessage = 'Application already submitted!';
      }
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false, jobApplicationMessage: errMessage },
      });
      yield handleApiError(status);
    }
  } catch (e) {
    message.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { formSubmitting: false },
    });
    console.error(e);
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_LIST_PORTAL_JOB, LIST_JOB),
    takeEvery(actions.INVOKE_GET_PORTAL_JOB, GET_JOB),
    takeEvery(actions.INVOKE_GET_PUBLIC_ORGANIZATION, GET_PUBLIC_ORGANIZATION),
    takeEvery(actions.INVOKE_GET_PORTAL_JOB_FORM, GET_PORTAL_JOB_FORM),
    takeEvery(actions.INVOKE_APPLY_PORTAL_JOB, APPLY_PORTAL_JOB),
  ]);
}
