import { PageHeader } from 'antd';
import React from 'react';
import { routes } from 'helpers/routes';
import { useHistory } from 'react-router-dom';
import MainHeader from 'components/MainHeader';

const AccountLayout = ({ children }) => {
  const history = useHistory();

  return (
    <div className="vh-100 d-flex flex-column">
      <MainHeader />
      <PageHeader
        className="site-page-header-responsive border-top border-bottom"
        onBack={() => history.push(routes.DASHBOARD)}
        title={<p className="m-0 fs-6 fw-bold">Account settings</p>}
        style={{
          padding: '0 15px',
        }}
        backIcon={<i className="bi bi-x-lg" />}
      />
      <div className="flex-grow-1 position-relative">
        <div className="position-absolute bottom-0 top-0 overflow-auto w-100">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AccountLayout;
