import actions from './actions';

const initialState = {
  forms: [],
  currentForm: null,
  loading: false,
  formsTotalResults: 0,
  formsTotalPages: 0,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
