import { message } from 'antd';
import { AIAgent } from 'apis/APISDK';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_WORKFLOW_AI_AGENT(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      AIAgent.createAIAgent,
      payload
    );

    if (success) {
      message.success('AI Agent created successfully');
      yield put({
        type: actions.INVOKE_LIST_AI_AGENT,
      });
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* UPDATE_WORKFLOW_AI_AGENT(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      AIAgent.updateAIAgent,
      payload
    );

    if (success) {
      yield put({
        type: actions.INVOKE_LIST_AI_AGENT,
      });
      message.success('AI Agent updated successfully');
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* DELETE_WORKFLOW_AI_AGENT(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { success, message: resMessage } = yield call(
      AIAgent.deleteAIAgent,
      payload
    );
    if (success) {
      message.success('AI Agent deleted successfully');
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_WORKFLOW_AI_AGENT(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });

  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(AIAgent.getAIAgent, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          currentAIAgent: data,
        },
      });
      payloads.next(data);
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* LIST_WORKFLOW_AI_AGENT(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(AIAgent.listAIAgents, payload);

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          aiAgentsList: data?.results,
          aiAgentTotalResults: data?.totalResults,
          aiAgentTotalPages: data?.totalPages,
        },
      });
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_AI_AGENT, CREATE_WORKFLOW_AI_AGENT),
    takeEvery(actions.INVOKE_UPDATE_AI_AGENT, UPDATE_WORKFLOW_AI_AGENT),
    takeEvery(actions.INVOKE_DELETE_AI_AGENT, DELETE_WORKFLOW_AI_AGENT),
    takeEvery(actions.INVOKE_LIST_AI_AGENT, LIST_WORKFLOW_AI_AGENT),

    takeEvery(actions.INVOKE_GET_AI_AGENT, GET_WORKFLOW_AI_AGENT),
  ]);
}
